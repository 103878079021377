import React from 'react'

// import { makeStyles } from '@material-ui/core/styles'
import { sendGaEvent } from '../../GaEvent'
import ButtonUsagi from '../ui/ButtonUsagi'

// import Button from '../ui/Button'

// const useButtonStyles = makeStyles(theme => ({
//   root: { width: 240 }
// }))

export const StartAnalytics = () => {
  return (
    <div style={{ width: 240, margin: '0 auto' }}>
      <ButtonUsagi
        text='自分も診断してみる'
        href='/analysis'
        color='primary'
        onClick={() => sendGaEvent('go_to_analysis', 'analysis', 'シェア結果')}
      />
    </div>
  )
}

export default StartAnalytics
