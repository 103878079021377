import PropTypes from 'prop-types'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import styles from './AnnounceDoctor.module.scss'

export function AnnounceDoctor({ children, text = '', textStyle = {} }) {
  return (
    <div className={styles.container}>
      <div className={styles.suggest} style={textStyle}>
        {ReactHtmlParser(text)}
        {children}
      </div>
      <div className={styles.usagiDoctor} />
    </div>
  )
}
export default AnnounceDoctor

AnnounceDoctor.propTypes = {
  text: PropTypes.string,
  textStyle: PropTypes.object
}
