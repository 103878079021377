import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useLatestRecordId } from '../../hooks/useLatestRecordId'
import { fetchRecord } from '../../stores/record'
import { makeResult } from '../../stores/result'
import Button from '../ui/Button'
import ListCatalog from './ListCatalog'

import styles from './RecommendSection.module.scss'

export const RecommendSection = ({ articleId }) => {
  const dispatch = useDispatch()
  const latestRecordId = useLatestRecordId()
  const { contentsTop5 } = useSelector(state => state.result)
  const [suggestList, setSuggestList] = useState([])

  useEffect(() => {
    dispatch(fetchRecord(latestRecordId)).then(action => {
      dispatch(makeResult(action.payload))
    })
  }, [dispatch, latestRecordId])

  useEffect(() => {
    setSuggestList(contentsTop5.filter(el => el.copingContentsId !== articleId))
  }, [contentsTop5, articleId])

  return (
    <div className={styles.container}>
      <div
        className={ClassNames(styles.suggestList, {
          [styles.hide]: latestRecordId == null
        })}
      >
        <h2>あなたにおすすめの解消法</h2>
        <ListCatalog articles={suggestList} />
      </div>
      <div className={styles.articleActions}>
        <div className={styles.actionButton}>
          <Button
            text='その他の解消法'
            color='primary'
            href={`/article/list`}
            fullWidth
          />
        </div>
        <div
          className={ClassNames(styles.actionButton, {
            [styles.hide]: latestRecordId == null
          })}
        >
          <Button
            text='診断結果に戻る'
            color='secondary'
            href={`/result/`}
            fullWidth
          />
        </div>
        <div
          className={ClassNames(styles.actionButton, {
            [styles.hide]: latestRecordId != null
          })}
        >
          <Button
            text='診断して解消法をみつける'
            color='primary'
            href={`/analysis`}
            fullWidth
          />
        </div>
      </div>
    </div>
  )
}

RecommendSection.propTypes = {
  articleId: PropTypes.number
}
export default RecommendSection
