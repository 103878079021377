import React from 'react'
import { useSelector } from 'react-redux'

import { sendGaEvent } from '../../GaEvent'
import ButtonUsagi from '../ui/ButtonUsagi'
import { ChatBaloonDoctor } from '../ui/ChatBaloon'
import LineSyncButton from '../ui/LineSyncButton'

import styles from './AccountGuide.module.scss'

const signUp = () => {
  return (
    <ChatBaloonDoctor>
      <big>
        <span className={styles.red}>診断結果は一定期間で消えてしまう</span>
        のじゃ。あとで見るなら<b>保存する</b>のがおすすめですぞ。
        <br />
        <br />
        <div className={styles.button}>
          <ButtonUsagi
            text='診断結果を保存する（無料）'
            size='small'
            onClick={() => sendGaEvent('go_to_signup', 'login', '分析結果保存')}
            href='/signup'
          />
        </div>
      </big>
    </ChatBaloonDoctor>
  )
}

const lineSync = () => (
  <ChatBaloonDoctor>
    ストレスは日々変化するので定期的な診断がかかせないのじゃ。
    わしと友達になると、LINEでプチ診断ができますぞ！
    <LineSyncButton
      onClick={() => sendGaEvent('sync_line_friends', 'friend', '分析結果画面')}
    />
  </ChatBaloonDoctor>
)

export const AccountGuide = () => {
  const { uid } = useSelector(state => state.user.auth)
  const { lineUserId } = useSelector(state => state.user.data)
  return (
    <div className={styles.container}>
      {uid == null ? (
        signUp()
      ) : lineUserId == null || lineUserId === '' ? (
        lineSync()
      ) : (
        <></>
      )}
      {/* <br />
        <img className={styles.stampImage} src={stamp} alt='stmp' />
        <br />
        <br />
        今なら友達登録で無料スタンププレゼント中じゃ！
        <br /> */}
    </div>
  )
}

export default AccountGuide
