import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import MainMenu from './MainMenu'

import styles from './Header.module.scss'

export default function Header({ mainMenuEnabled = true }) {
  return (
    <div className={styles.container}>
      <div className={styles.alphaMark}>アルファ版</div>
      <Link to='/'>
        <div className={styles.logo} />
      </Link>
      {mainMenuEnabled ? <MainMenu /> : <></>}
    </div>
  )
}

Header.propTypes = {
  mainMenuEnabled: PropTypes.bool
}
