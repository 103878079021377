import classNames from 'classnames'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { resetState } from '../../stores/localStore'
import { deleteHistory } from '../../stores/recordHistory'
import { Button } from '../ui/Button'
import LoginStatus from './LoginStatus'
import MainMenuItems from './MainMenuItems'

import styles from './MainMenu.module.scss'

export default function MainMenu() {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const { auth } = useSelector(state => state.user)
  const onClickReset = async () => {
    dispatch(resetState())
    if (auth.uid != null) {
      dispatch(deleteHistory(auth.uid))
    }
    setIsOpen(false)
  }

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.menuIcon, { [styles.isOpen]: isOpen })}
        onClick={e => setIsOpen(!isOpen)}
      />
      {isOpen ? (
        <motion.div
          animate={{ opacity: 1.0 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className={styles.menuContainer}>
            <LoginStatus />
            <MainMenuItems onClickLink={e => setIsOpen(false)} />
            {process.env.REACT_APP_ENV !== 'production' ? (
              <Button
                text='初期化(Debug)'
                size='small'
                onClick={() => onClickReset()}
              />
            ) : (
              <></>
            )}
          </div>
        </motion.div>
      ) : (
        <></>
      )}
    </div>
  )
}
