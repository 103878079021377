import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { sendGaEvent } from '../../GaEvent'
import ButtonUsagi from '../ui/ButtonUsagi'

import styles from './RankingChart.module.scss'

const DUMMY_ENTRY = [
  { name: '何もできないので、うまく行くことを願ってみる', value: 5.12 },
  { name: '夜布団の中で、一日の失敗を振り返る', value: 3.64 },
  { name: '友達に責任を押し付ける', value: 1.53 },
  { name: 'トイレにこもる', value: 1.05 },
  { name: '家の周りを散歩する', value: 0.87 }
]

export const RankingChart = ({ entries = [], color = 'c', masked = false }) => {
  const maxValue = Math.max(...entries.map(e => e.value))
  const [displayEntries, setDisplayEntries] = useState([])
  const barStyle = value => ({
    width: `${(value / maxValue) * 100}%`
  })

  useEffect(() => {
    if (masked) {
      setDisplayEntries(DUMMY_ENTRY)
    } else {
      setDisplayEntries(entries)
    }
  }, [entries, masked])

  const renderLoginOverlay = () => (
    <div className={styles.overlay}>
      <div className={styles.doctor} />
      <div className={styles.text}>
        診断結果を保存すると、「やらないほうがいいこと」も出せますぞ。
      </div>
      <div className={styles.buttons}>
        <ButtonUsagi
          text='保存して見る（無料）'
          size='small'
          href='/signup'
          color='primary'
          onClick={() => sendGaEvent('go_to_signup', 'login', 'ランキング表示')}
          fullWidth
        />
      </div>
    </div>
  )

  return (
    <div className={styles.container}>
      {displayEntries.map((entry, i) => (
        <div
          className={classNames(styles.row, {
            [styles.masked]: masked
          })}
          key={i}
        >
          <div
            className={classNames(styles.rank, {
              [styles.gold]: i === 0,
              [styles.silver]: i === 1,
              [styles.bronds]: i === 2
            })}
          >
            {i + 1}
          </div>
          <div className={styles.graph}>
            <div className={styles.name}>{entry.name}</div>
            <div
              className={classNames(styles.bar, {
                [styles.primary]: color === 'primary',
                [styles.secondary]: color === 'secondary'
              })}
              style={barStyle(entry.value)}
            />
          </div>
        </div>
      ))}
      {masked && renderLoginOverlay()}
    </div>
  )
}

RankingChart.propTypes = {
  entries: PropTypes.array,
  color: PropTypes.string,
  masked: PropTypes.bool
}

export default RankingChart
