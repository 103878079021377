import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { sendGaEvent } from '../../GaEvent'
import ButtonUsagi from '../ui/ButtonUsagi'
import TimelineChart from '../ui/TimelineChart'

import styles from './StressTimeline.module.scss'

export const StressTimeline = ({ thin = false }) => {
  const { data } = useSelector(state => state.recordHistory)
  const { auth } = useSelector(state => state.user)
  const [timelineData, setTimelineData] = useState([])
  const history = useHistory()

  useEffect(() => {
    if (auth.uid == null) {
      const dt = new Date()
      const m = dt.getMonth()
      const d = dt.getDate()
      const values = [25.3, 28.7, 29.5, 30.6, 24.5, 21.1, 23.3, 25.4, 24.5]
      setTimelineData(
        values.map((v, i) => ({ date: new Date(2022, m, d + i), value: v }))
      )
    } else {
      setTimelineData(
        data.map(d => ({
          date: new Date(d.createdAt.seconds * 1000),
          value: d.result.stressLevel
        }))
      )
    }
  }, [data, auth])

  const renderLoginOverlay = () => (
    <div className={styles.overlay}>
      <div className={styles.doctor} />
      <div className={styles.text}>
        診断結果を保存すると、ストレスレベルの推移を見られるんじゃ。
      </div>
      <div className={styles.buttons}>
        <ButtonUsagi
          text='保存して推移を見る（無料）'
          href='/signup'
          color='primary'
          size='small'
          onClick={() =>
            sendGaEvent('go_to_signup', 'login', '結果画面グラフ表示')
          }
          fullWidth
        />
      </div>
    </div>
  )

  const renderLoginOverlayThin = () => (
    <div
      className={styles.overlayThin}
      onClick={() => {
        history.push('/signup')
        sendGaEvent('go_to_signup', 'login', 'トップグラフ表示')
      }}
    >
      <div className={styles.text}>
        <b>結果を保存</b>
        <br />
        するとストレス
        <br />
        レベルの推移が
        <br />
        表示されます
        <br />
      </div>
    </div>
  )

  return (
    <div className={styles.container}>
      <TimelineChart data={timelineData} thin={thin} />
      {auth.uid == null &&
        (thin === false ? renderLoginOverlay() : renderLoginOverlayThin())}
    </div>
  )
}

StressTimeline.propTypes = {
  thin: PropTypes.bool
}
export default StressTimeline
