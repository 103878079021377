import PropTypes from 'prop-types'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from '../ui/Button'

import doctor from '../../assets/images/illust/doctor_11.svg'

export function DoctorDialog({ title = '', text = '', buttons = [] }, ref) {
  const [open, setOpen] = useState(false)
  const [args, setArgs] = useState(null)

  useImperativeHandle(
    ref,
    () => ({
      handleOpen(args) {
        setArgs(args)
        setOpen(true)
      }
    }),
    []
  )

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <img
          src={doctor}
          alt='doctor'
          style={{ width: '150px', margin: '0 auto 16px auto' }}
        />
        <DialogContentText id='alert-dialog-description'>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.map(b => (
          <Button
            key={b.name}
            onClick={e => {
              b.value(args)
              handleClose()
            }}
            variant='text'
            color={b.color || 'primary'}
            text={b.name}
          />
        ))}
      </DialogActions>
    </Dialog>
  )
}

DoctorDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttons: PropTypes.array
}

export default forwardRef(DoctorDialog)
