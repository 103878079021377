// ==== getWeightedItem format ====
// inputSample: {
//   exception: [
//     "perceivedStressScale1n001",
//     ...
//   ],
//   randomize: false // default is true
// }
// outputSample: [
//   "cissSf6n001",
//   "tac247n001",
//   ...
// ]
// ========================

import itemDataJson from './itemData.json'
import scaleDataJson from './scaleData.json'
import typeDataJson from './typeData.json'

const RANDOMIZE_COEFF = 0.4

export const getWeightedItem = ({ exception = [], randomize = true }) => {
  let items = itemDataJson.data.filter(
    element => !exception.includes(element.itemId)
  )
  items = items.map(itemElement => {
    const weight = scaleDataJson.data.find(
      scaleElement => scaleElement.scaleId === itemElement.scaleId
    ).weight
    return { ...itemElement, weight }
  })
  // うさタイプに寄与する質問の補正
  // うさタイプに寄与する質問は線形補間 (weight, 1, 0.5)
  items = items.map(element => {
    const typeData = typeDataJson.data.find(
      ({ refScaleId }) => element.scaleId === refScaleId
    )
    const typeDataCoeff = typeData ? 0.5 : 0
    const weight = (1 - typeDataCoeff) * element.weight + typeDataCoeff * 1
    return { ...element, weight }
  })
  // weightをrandomize
  // 40%をランダムweightとして寄与させる 線形補間(weight, random, 0.4)
  const randomizeCoeff = randomize ? RANDOMIZE_COEFF : 0
  items = items.map(element => {
    const weight =
      (1 - randomizeCoeff) * element.weight + randomizeCoeff * Math.random()
    return { ...element, weight }
  })
  items.sort((a, b) => b.weight - a.weight)
  items = items.map(element => element.itemId)
  return items
}
