import t011 from '../../assets/images/slider/type_1/type_1_1.svg'
import t012 from '../../assets/images/slider/type_1/type_1_2.svg'
import t013 from '../../assets/images/slider/type_1/type_1_3.svg'
import t014 from '../../assets/images/slider/type_1/type_1_4.svg'
import t015 from '../../assets/images/slider/type_1/type_1_5.svg'
import t016 from '../../assets/images/slider/type_1/type_1_6.svg'
import t017 from '../../assets/images/slider/type_1/type_1_7.svg'
import t018 from '../../assets/images/slider/type_1/type_1_8.svg'
import t019 from '../../assets/images/slider/type_1/type_1_9.svg'
import t021 from '../../assets/images/slider/type_2/type_2_1.svg'
import t022 from '../../assets/images/slider/type_2/type_2_2.svg'
import t023 from '../../assets/images/slider/type_2/type_2_3.svg'
import t024 from '../../assets/images/slider/type_2/type_2_4.svg'
import t025 from '../../assets/images/slider/type_2/type_2_5.svg'
import t026 from '../../assets/images/slider/type_2/type_2_6.svg'
import t027 from '../../assets/images/slider/type_2/type_2_7.svg'
import t028 from '../../assets/images/slider/type_2/type_2_8.svg'
import t029 from '../../assets/images/slider/type_2/type_2_9.svg'
import t031 from '../../assets/images/slider/type_3/type_3_1.svg'
import t032 from '../../assets/images/slider/type_3/type_3_2.svg'
import t033 from '../../assets/images/slider/type_3/type_3_3.svg'
import t034 from '../../assets/images/slider/type_3/type_3_4.svg'
import t035 from '../../assets/images/slider/type_3/type_3_5.svg'
import t036 from '../../assets/images/slider/type_3/type_3_6.svg'
import t037 from '../../assets/images/slider/type_3/type_3_7.svg'
import t038 from '../../assets/images/slider/type_3/type_3_8.svg'
import t039 from '../../assets/images/slider/type_3/type_3_9.svg'
import t041 from '../../assets/images/slider/type_4/type_4_1.svg'
import t042 from '../../assets/images/slider/type_4/type_4_2.svg'
import t043 from '../../assets/images/slider/type_4/type_4_3.svg'
import t044 from '../../assets/images/slider/type_4/type_4_4.svg'
import t045 from '../../assets/images/slider/type_4/type_4_5.svg'
import t046 from '../../assets/images/slider/type_4/type_4_6.svg'
import t047 from '../../assets/images/slider/type_4/type_4_7.svg'
import t048 from '../../assets/images/slider/type_4/type_4_8.svg'
import t049 from '../../assets/images/slider/type_4/type_4_9.svg'
import t051 from '../../assets/images/slider/type_5/type_5_1.svg'
import t052 from '../../assets/images/slider/type_5/type_5_2.svg'
import t053 from '../../assets/images/slider/type_5/type_5_3.svg'
import t054 from '../../assets/images/slider/type_5/type_5_4.svg'
import t055 from '../../assets/images/slider/type_5/type_5_5.svg'
import t056 from '../../assets/images/slider/type_5/type_5_6.svg'
import t057 from '../../assets/images/slider/type_5/type_5_7.svg'
import t058 from '../../assets/images/slider/type_5/type_5_8.svg'
import t059 from '../../assets/images/slider/type_5/type_5_9.svg'
import t061 from '../../assets/images/slider/type_6/type_6_1.svg'
import t062 from '../../assets/images/slider/type_6/type_6_2.svg'
import t063 from '../../assets/images/slider/type_6/type_6_3.svg'
import t064 from '../../assets/images/slider/type_6/type_6_4.svg'
import t065 from '../../assets/images/slider/type_6/type_6_5.svg'
import t066 from '../../assets/images/slider/type_6/type_6_6.svg'
import t067 from '../../assets/images/slider/type_6/type_6_7.svg'
import t068 from '../../assets/images/slider/type_6/type_6_8.svg'
import t069 from '../../assets/images/slider/type_6/type_6_9.svg'
import t071 from '../../assets/images/slider/type_7/type_7_1.svg'
import t072 from '../../assets/images/slider/type_7/type_7_2.svg'
import t073 from '../../assets/images/slider/type_7/type_7_3.svg'
import t074 from '../../assets/images/slider/type_7/type_7_4.svg'
import t075 from '../../assets/images/slider/type_7/type_7_5.svg'
import t076 from '../../assets/images/slider/type_7/type_7_6.svg'
import t077 from '../../assets/images/slider/type_7/type_7_7.svg'
import t078 from '../../assets/images/slider/type_7/type_7_8.svg'
import t079 from '../../assets/images/slider/type_7/type_7_9.svg'
import t081 from '../../assets/images/slider/type_8/type_8_1.svg'
import t082 from '../../assets/images/slider/type_8/type_8_2.svg'
import t083 from '../../assets/images/slider/type_8/type_8_3.svg'
import t084 from '../../assets/images/slider/type_8/type_8_4.svg'
import t085 from '../../assets/images/slider/type_8/type_8_5.svg'
import t086 from '../../assets/images/slider/type_8/type_8_6.svg'
import t087 from '../../assets/images/slider/type_8/type_8_7.svg'
import t088 from '../../assets/images/slider/type_8/type_8_8.svg'
import t089 from '../../assets/images/slider/type_8/type_8_9.svg'
import t091 from '../../assets/images/slider/type_9/type_9_1.svg'
import t092 from '../../assets/images/slider/type_9/type_9_2.svg'
import t093 from '../../assets/images/slider/type_9/type_9_3.svg'
import t094 from '../../assets/images/slider/type_9/type_9_4.svg'
import t095 from '../../assets/images/slider/type_9/type_9_5.svg'
import t096 from '../../assets/images/slider/type_9/type_9_6.svg'
import t097 from '../../assets/images/slider/type_9/type_9_7.svg'
import t098 from '../../assets/images/slider/type_9/type_9_8.svg'
import t099 from '../../assets/images/slider/type_9/type_9_9.svg'
import t101 from '../../assets/images/slider/type_10/type_10_1.svg'
import t102 from '../../assets/images/slider/type_10/type_10_2.svg'
import t103 from '../../assets/images/slider/type_10/type_10_3.svg'
import t104 from '../../assets/images/slider/type_10/type_10_4.svg'
import t105 from '../../assets/images/slider/type_10/type_10_5.svg'
import t106 from '../../assets/images/slider/type_10/type_10_6.svg'
import t107 from '../../assets/images/slider/type_10/type_10_7.svg'
import t108 from '../../assets/images/slider/type_10/type_10_8.svg'
import t109 from '../../assets/images/slider/type_10/type_10_9.svg'
import t111 from '../../assets/images/slider/type_11/type_11_1.svg'
import t112 from '../../assets/images/slider/type_11/type_11_2.svg'
import t113 from '../../assets/images/slider/type_11/type_11_3.svg'
import t114 from '../../assets/images/slider/type_11/type_11_4.svg'
import t115 from '../../assets/images/slider/type_11/type_11_5.svg'
import t116 from '../../assets/images/slider/type_11/type_11_6.svg'
import t117 from '../../assets/images/slider/type_11/type_11_7.svg'
import t118 from '../../assets/images/slider/type_11/type_11_8.svg'
import t119 from '../../assets/images/slider/type_11/type_11_9.svg'
import t121 from '../../assets/images/slider/type_12/type_12_1.svg'
import t122 from '../../assets/images/slider/type_12/type_12_2.svg'
import t123 from '../../assets/images/slider/type_12/type_12_3.svg'
import t124 from '../../assets/images/slider/type_12/type_12_4.svg'
import t125 from '../../assets/images/slider/type_12/type_12_5.svg'
import t126 from '../../assets/images/slider/type_12/type_12_6.svg'
import t127 from '../../assets/images/slider/type_12/type_12_7.svg'
import t128 from '../../assets/images/slider/type_12/type_12_8.svg'
import t129 from '../../assets/images/slider/type_12/type_12_9.svg'
import t131 from '../../assets/images/slider/type_13/type_13_1.svg'
import t132 from '../../assets/images/slider/type_13/type_13_2.svg'
import t133 from '../../assets/images/slider/type_13/type_13_3.svg'
import t134 from '../../assets/images/slider/type_13/type_13_4.svg'
import t135 from '../../assets/images/slider/type_13/type_13_5.svg'
import t136 from '../../assets/images/slider/type_13/type_13_6.svg'
import t137 from '../../assets/images/slider/type_13/type_13_7.svg'
import t138 from '../../assets/images/slider/type_13/type_13_8.svg'
import t139 from '../../assets/images/slider/type_13/type_13_9.svg'
import t141 from '../../assets/images/slider/type_14/type_14_1.svg'
import t142 from '../../assets/images/slider/type_14/type_14_2.svg'
import t143 from '../../assets/images/slider/type_14/type_14_3.svg'
import t144 from '../../assets/images/slider/type_14/type_14_4.svg'
import t145 from '../../assets/images/slider/type_14/type_14_5.svg'
import t146 from '../../assets/images/slider/type_14/type_14_6.svg'
import t147 from '../../assets/images/slider/type_14/type_14_7.svg'
import t148 from '../../assets/images/slider/type_14/type_14_8.svg'
import t149 from '../../assets/images/slider/type_14/type_14_9.svg'
import t151 from '../../assets/images/slider/type_15/type_15_1.svg'
import t152 from '../../assets/images/slider/type_15/type_15_2.svg'
import t153 from '../../assets/images/slider/type_15/type_15_3.svg'
import t154 from '../../assets/images/slider/type_15/type_15_4.svg'
import t155 from '../../assets/images/slider/type_15/type_15_5.svg'
import t156 from '../../assets/images/slider/type_15/type_15_6.svg'
import t157 from '../../assets/images/slider/type_15/type_15_7.svg'
import t158 from '../../assets/images/slider/type_15/type_15_8.svg'
import t159 from '../../assets/images/slider/type_15/type_15_9.svg'
import t161 from '../../assets/images/slider/type_16/type_16_1.svg'
import t162 from '../../assets/images/slider/type_16/type_16_2.svg'
import t163 from '../../assets/images/slider/type_16/type_16_3.svg'
import t164 from '../../assets/images/slider/type_16/type_16_4.svg'
import t165 from '../../assets/images/slider/type_16/type_16_5.svg'
import t166 from '../../assets/images/slider/type_16/type_16_6.svg'
import t167 from '../../assets/images/slider/type_16/type_16_7.svg'
import t168 from '../../assets/images/slider/type_16/type_16_8.svg'
import t169 from '../../assets/images/slider/type_16/type_16_9.svg'
import t171 from '../../assets/images/slider/type_17/type_17_1.svg'
import t172 from '../../assets/images/slider/type_17/type_17_2.svg'
import t173 from '../../assets/images/slider/type_17/type_17_3.svg'
import t174 from '../../assets/images/slider/type_17/type_17_4.svg'
import t175 from '../../assets/images/slider/type_17/type_17_5.svg'
import t176 from '../../assets/images/slider/type_17/type_17_6.svg'
import t177 from '../../assets/images/slider/type_17/type_17_7.svg'
import t178 from '../../assets/images/slider/type_17/type_17_8.svg'
import t179 from '../../assets/images/slider/type_17/type_17_9.svg'

export default {
  type1: [t011, t012, t013, t014, t015, t016, t017, t018, t019],
  type2: [t021, t022, t023, t024, t025, t026, t027, t028, t029],
  type3: [t031, t032, t033, t034, t035, t036, t037, t038, t039],
  type4: [t041, t042, t043, t044, t045, t046, t047, t048, t049],
  type5: [t051, t052, t053, t054, t055, t056, t057, t058, t059],
  type6: [t061, t062, t063, t064, t065, t066, t067, t068, t069],
  type7: [t071, t072, t073, t074, t075, t076, t077, t078, t079],
  type8: [t081, t082, t083, t084, t085, t086, t087, t088, t089],
  type9: [t091, t092, t093, t094, t095, t096, t097, t098, t099],
  type10: [t101, t102, t103, t104, t105, t106, t107, t108, t109],
  type11: [t111, t112, t113, t114, t115, t116, t117, t118, t119],
  type12: [t121, t122, t123, t124, t125, t126, t127, t128, t129],
  type13: [t131, t132, t133, t134, t135, t136, t137, t138, t139],
  type14: [t141, t142, t143, t144, t145, t146, t147, t148, t149],
  type15: [t151, t152, t153, t154, t155, t156, t157, t158, t159],
  type16: [t161, t162, t163, t164, t165, t166, t167, t168, t169],
  type17: [t171, t172, t173, t174, t175, t176, t177, t178, t179]
}
