import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const useTracking = (
  trackingId = process.env.REACT_APP_UA_TRACKING_ID
) => {
  const { listen } = useHistory()

  useEffect(() => {
    const unlisten = listen(location => {
      window.setTimeout(() => {
        if (!window.gtag) return
        if (!trackingId) {
          console.log(
            'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
          )
          return
        }
        window.gtag('config', trackingId, { page_path: location.pathname })
        window.twq('track', 'PageView')
        window._lt('send', 'pv', [process.env.REACT_APP_LINE_TRACKING_TAG_ID])
      }, 0)
    })

    return unlisten
  }, [trackingId, listen])
}
