import React from 'react'
import { Helmet } from 'react-helmet-async'

import doctor from '../../assets/images/illust/doctor.svg'
import photoIbaraki from '../../assets/images/photo/ibaraki.png'
import photoNishimoto from '../../assets/images/photo/nishimoto.png'
import styles from './ServiceConcept.module.scss'

export default function ServiceConcept() {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>サービスコンセプト</title>
      </Helmet>
      <h3>うさばら診断とは</h3>
      <img src={doctor} className={styles.doctor} alt='doctor' />
      <div className={styles.block}>
        <h2>Service Concept</h2>
        <p>
          あなたの状態を心理学や認知科学といった様々な分野のデータをもとに分析し、あなたに合ったストレス対処法を紹介します。
          周りの人に言いにくく、つい溜め込みがちだったストレスについて、もっと手軽に知って発散でき、気軽に話せる世の中になってほしい。
          ちょっと大げさかもしれませんが、そんな未来を目指したサービスです。
        </p>
      </div>
      <div className={styles.block}>
        <h2>Mission</h2>
        <div className={styles.missionText}>人々が自分を知る機会を増やす</div>
        <p>
          私達は様々な側面からの専門知識を基に、ストレスをカジュアルに知ることができるようにし、その人が本来持つ姿に気づき、新たな可能性に向かう手助けをします。
        </p>
      </div>
      <div className={styles.block}>
        <h2>Team</h2>
        <p>
          USABARAの診断ロジックや各種アドバイス記事は、脳科学および公衆衛生学の専門家チームによって構築されています。
        </p>

        <div className={styles.personBlock}>
          <img src={photoIbaraki} className={styles.photo} alt='ibaraki' />
          <div className={styles.name}>茨城 拓也</div>
          <div className={styles.ename}>Ibaraki Takuya</div>
          <p>
            早稲田大学文学部心理学科卒。東京大学大学院　医学系研究科　医科学修士課程（脳神経医学専攻）修了（MMedSc）。同・医学博士課程中退後、2014年4月にNTTデータ経営研究所に入社。総務省「次世代人工知能社会実装WG」構成員（2017年、第六回）。早稲田大学商学部招聘講師(2018年)。国際会議「脳科学の事業応用」第一回実行委員長（2019年9月）。
            神経科学を基軸とした新規事業の創生や研究開発の支援に多数従事。分野は製造業を中心に、医療、ヘルスケア、広告、web、人事、金融と多岐に渡る。
          </p>
        </div>

        <div className={styles.personBlock}>
          <img src={photoNishimoto} className={styles.photo} alt='nishimoto' />
          <div className={styles.name}>西本 真寛</div>
          <div className={styles.ename}>Nishimoto Masahiro</div>
          <p>
            Public Health Consulting llc. 共同創業者。
            ヘルスケア事業のコンサルタント。はたらく人の健康づくり研究者。シンクタンクで経産省の健康経営オフィスレポート等に参画。広告代理店の健康経営や、コンサルティング会社の働き方改革を支援。well-beingのための介入コンテンツの企画・監修や、ヘルステックのマーケティングなども支援している。
            東京大学大学院 医学系研究科 修了（MPH）、慶應義塾大学SFC研究所
            上席所員。
          </p>
        </div>
      </div>
    </div>
  )
}
