import 'firebase/auth'

import firebase from 'firebase/app'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { useLatestRecordId } from '../hooks/useLatestRecordId'
import { useTracking } from '../hooks/useTracking'
import { loadState } from '../stores/localStore'
import { assignUserId2LocalRecord } from '../stores/record'
import { subscribeHistory } from '../stores/recordHistory'
import { subscribeUser } from '../stores/user'
import Analysis from './analysis/Analysis'
import AppFrame from './app/AppFrame'
import Contact from './app/Contact'
import Footer from './app/Footer'
import Header from './app/Header'
import LineSyncRecommend from './app/LineSyncRecommend'
import Login from './app/Login'
import Logout from './app/Logout'
import PrivacyPolicy from './app/PrivacyPolicy'
import RabbitTypeList from './app/RabbitTypeList'
import ServiceConcept from './app/ServiceConcept'
import TermsOfService from './app/TermsOfService'
import UnderConstruction from './app/UnderConstruction'
import Article from './article/Article'
import Favorites from './article/Favorites'
import ArticleList from './article/List'
import Home from './home/Home'
import Result from './result/Result'
import ScrollToTop from './ScrollToTop'

import styles from './App.module.scss'

function App() {
  const dispatch = useDispatch()
  const { disableFooter } = useSelector(state => state.app)
  const latestRecordId = useLatestRecordId()

  useEffect(() => {
    dispatch(loadState())
    firebase.auth().onAuthStateChanged(user => {
      if (!user) return
      dispatch(subscribeUser(user))
      dispatch(subscribeHistory(user))
      // To salvage records created when not logged in
      dispatch(assignUserId2LocalRecord(user.uid))
    })
  }, [dispatch])

  useTracking(process.env.REACT_APP_UA_TRACKING_ID)

  if (process.env.REACT_APP_UNDER_CONSTRUCTION === 'true') {
    return <UnderConstruction />
  }

  const RenderRoutes = () => (
    <Switch key='app-root'>
      <Route path='/' exact render={() => <Home recordId={latestRecordId} />} />
      <Route path='/analysis' exact>
        <Analysis recordId={latestRecordId} />
      </Route>
      <Route path='/analysis/new' exact render={() => <Analysis />} />
      <Route
        path='/article/list'
        exact
        render={({ match }) => <ArticleList />}
      />
      <Route
        path='/article/favorites'
        exact
        render={({ match }) => <Favorites />}
      />
      <Route
        path='/article/:id'
        exact
        render={({ match }) => (
          <Article articleId={parseInt(match.params.id)} />
        )}
      />
      <Route path='/concept' exact render={() => <ServiceConcept />} />
      <Route path='/contact' exact render={() => <Contact />} />
      <Route path='/policy' exact render={() => <PrivacyPolicy />} />
      <Route path='/tos' exact render={() => <TermsOfService />} />
      <Route
        path='/result/:id'
        exact
        render={({ match }) => <Result recordId={match.params.id} />}
      />
      <Route
        path='/result'
        exact
        render={({ match }) => <Result recordId={latestRecordId} />}
      />
      <Route path='/typelist' exact render={() => <RabbitTypeList />} />
      <Route path='/login' exact render={() => <Login />} />
      <Route path='/signup' exact render={() => <Login signup />} />
      <Route path='/logout' exact render={() => <Logout />} />
      <Route path='/linesync' exact render={() => <LineSyncRecommend />} />
    </Switch>
  )

  return (
    <div className={styles.container}>
      <AppFrame>
        <AnimatePresence exitBeforeEnter>
          <ScrollToTop>
            <>{RenderRoutes()}</>
          </ScrollToTop>
        </AnimatePresence>
      </AppFrame>
      <Footer footerEnabled={!disableFooter} />
      <Header />
    </div>
  )
}

export default App
