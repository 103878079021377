import PropTypes from 'prop-types'
import React from 'react'

import ListCatalog from '../article/ListCatalog'
import Button from '../ui/Button'

import styles from './ArticleSuggestList.module.scss'
export const ArticleSuggestList = ({ articles = [] }) => {
  if (articles.length === 0) return <></>
  return (
    <div className={styles.container}>
      <div className={styles.otherList}>
        <ListCatalog articles={articles.slice(1, articles.length)} />
        <div className={styles.button}>
          <Button
            text='more'
            variant='outlined'
            href='/article/list'
            fullWidth
          />
        </div>
      </div>
    </div>
  )
}

ArticleSuggestList.propTypes = {
  articles: PropTypes.array
}
export default ArticleSuggestList
