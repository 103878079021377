import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import { HomeAfterAnalytics } from './HomeAfterAnalytics'
import { HomeBeforeAnalysis } from './HomeBeforeAnalysis'

import styles from './Home.module.scss'

export function Home({ recordId }) {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>USABARA</title>
      </Helmet>
      <motion.div
        animate={{ opacity: 1.0 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {recordId == null ? (
          <HomeBeforeAnalysis />
        ) : (
          <HomeAfterAnalytics recordId={recordId} />
        )}
      </motion.div>
    </div>
  )
}
export default Home

Home.propTypes = {
  recordId: PropTypes.string
}
