import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import { Button } from '../ui/Button'

import styles from './AnswerContainer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: 240
  }
}))

export const AnswerContainer = ({
  children,
  questionText = '',
  onClickNext = () => {},
  onClickBack = () => {}
}) => {
  const classes = useStyles()
  const currentIndex = useSelector(state => state.analysis.currentIndex)
  const numQuestions = useSelector(state => state.analysis.numQuestions)
  return (
    <div
      className={styles.container}
      // For fitting iOS and Android widhout navigation
      style={{ height: window.innerHeight - 64 - 48 }}
    >
      <div className={styles.back}>
        <Button
          text='＜ 戻る'
          size='small'
          variant='text'
          onClick={onClickBack}
        />
      </div>
      <div className={styles.questionCell}>{questionText}</div>
      <div className={styles.childrenCell}>{children}</div>
      <div className={styles.actionCell}>
        <div className={styles.next}>
          <Button
            text='次へ'
            color='primary'
            variant='contained'
            classes={classes}
            onClick={onClickNext}
          />
        </div>
        <div className={styles.progress}>
          {currentIndex + 1}/{numQuestions}
        </div>
      </div>
    </div>
  )
}

AnswerContainer.propTypes = {
  questionText: PropTypes.string,
  onClickNext: PropTypes.func,
  onClickBack: PropTypes.func
}
