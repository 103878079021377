// Takram Confidential
// Copyright (C) 2019-Present Takram

import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'

import {
  FILTER_NONE,
  setArticleListFilter,
  updateArticleList
} from '../../stores/article'
import LoginGuide from '../app/LoginGuide'
import AnnounceDoctor from '../ui/AnnounceDoctor'
import ListCatalog from './ListCatalog'

import styles from './Favorites.module.scss'

export function Favorites() {
  const dispatch = useDispatch()
  const { articleList } = useSelector(state => state.article)
  const { auth, data } = useSelector(state => state.user)
  const [articles, setArticles] = useState([])

  useEffect(() => {
    dispatch(setArticleListFilter(FILTER_NONE))
    dispatch(updateArticleList())
  }, [dispatch])

  useEffect(() => {
    if (articles.length === 0) {
      // Keep article list
      setArticles(
        articleList.filter(a =>
          data.favoriteArticleIds.includes(a.copingContentsId)
        )
      )
    }
  }, [articleList, data.favoriteArticleIds])

  return (
    <div className={styles.container}>
      <Helmet>
        <title>お気に入り記事一覧</title>
      </Helmet>
      <motion.div
        animate={{ opacity: 1.0 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h3>お気に入り記事一覧</h3>
        {auth.uid == null ? (
          <LoginGuide message='ログインすると、お気に入りに記事を追加できますぞ' />
        ) : (
          <>
            {articles.length === 0 ? (
              <AnnounceDoctor text='お気に入りに記事を追加すると、ここに表示されますぞ' />
            ) : (
              <div className={styles.list}>
                <ListCatalog articles={articles} />
              </div>
            )}
          </>
        )}
      </motion.div>
    </div>
  )
}

export default Favorites
