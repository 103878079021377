import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { analyze, getWeightedItem } from '../logic/'
import { loadQuestion } from './question'

const { logicVersion } = loadQuestion()

export const INIT_NO_ERROR = 'analysis/init_no_error'
export const INIT_ERROR_ZERO_ITEMS = 'analysis/init_error_zero_items'

const deriveWeightedScript = (script, exception = [], num = 20) => {
  const { logicVersion, scriptVersion, data } = script
  const ids = getWeightedItem({ logicVersion, exception })
  const out = []
  ids.forEach(id => {
    data.forEach(q => {
      if (
        out.find(
          o => o.questionId === q.questionId && o.questionType !== 'slider'
        ) == null &&
        q.items.find(i => i.itemId === id) != null
      ) {
        out.push(q)
      }
    })
  })
  return { logicVersion, scriptVersion, data: out.slice(0, num) }
}

const makeAnalyzeInput = (script, input) => {
  return {
    logicVersion: script.logicVersion,
    scriptVersion: script.scriptVersion,
    data: Object.entries(input).map(([k, v]) => ({
      itemId: k,
      value: v
    }))
  }
}

export const init = createAsyncThunk(
  'analysis/init',
  async (record, thunkAPI) => {
    const { answeredItemIds } = thunkAPI.getState().record.data
    let error = INIT_NO_ERROR
    let lastInput = {}
    if (record != null) {
      lastInput = record.input.reduce((hash, elem) => {
        hash[elem.itemId] = elem.value
        return hash
      }, {})
    }
    const script = deriveWeightedScript(loadQuestion(), answeredItemIds)
    if (script.data.length === 0) error = INIT_ERROR_ZERO_ITEMS
    return { script, lastInput, answeredItemIds, error }
  }
)

export const addAnsweredItemIds = createAsyncThunk(
  'analysis/addAnsweredItemIds',
  async (args, thunkAPI) => ({ ids: args })
)

export const clearExceptonItemIds = createAsyncThunk(
  'analysis/clearExceptonItemIds',
  async (args, thunkAPI) => ({})
)

const validateAnsweredItemIds = ids => {
  const leftIds = getWeightedItem({ logicVersion, ids })
  return leftIds.length === 0 ? [] : ids
  // return itemData.data.length <= ids.length ? [] : ids
}

export const analysis = createSlice({
  name: 'analysis',
  initialState: {
    script: {},
    numQuestions: 0,
    currentIndex: 0,
    currentInput: {},
    currentStressLevel: 0,
    currentPrecision: 0,
    currentAnsweredItemIds: [],
    isFinish: false
  },
  reducers: {
    next: state => {
      const next = state.currentIndex + 1
      const { script, currentInput } = state
      if (next >= state.numQuestions) {
        state.isFinish = true
      } else {
        const result = analyze(makeAnalyzeInput(script, currentInput))
        state.currentIndex = next
        state.currentPrecision = result.precision
        state.currentStressLevel = result.stressLevel
      }
    },
    prev: state => {
      const prev = state.currentIndex - 1
      if (prev >= 0) {
        state.currentIndex = prev
      }
    },
    changeValue: (state, action) => {
      state.currentInput = { ...state.currentInput, ...action.payload }
    },
    forceFinish: state => {
      state.isFinish = true
    }
  },
  extraReducers: {
    [init.fulfilled]: (state, action) => {
      const { script, lastInput, answeredItemIds, error } = action.payload
      if (error !== INIT_NO_ERROR) {
        console.warn('Init analysis error: ', error)
        return
      }
      const result = analyze(makeAnalyzeInput(script, lastInput))
      state.script = script
      state.currentInput = lastInput
      state.currentIndex = 0
      state.currentPrecision = result.precision
      state.currentStressLevel = result.stressLevel
      state.currentAnsweredItemIds = answeredItemIds
      state.numQuestions = script.data.length
    },
    [addAnsweredItemIds.fulfilled]: (state, action) => {
      const ids = Array.from(
        new Set(state.currentAnsweredItemIds.concat(action.payload.ids))
      )
      state.currentAnsweredItemIds = validateAnsweredItemIds(ids)
    },
    [clearExceptonItemIds.fulfilled]: (state, action) => {
      state.currentAnsweredItemIds = []
    }
  }
})

export const { forceFinish, next, prev, changeValue } = analysis.actions

export default analysis.reducer
