import PropTypes from 'prop-types'
import React from 'react'

import { Histgram } from './Histgram'

import styles from './LevelMeter.module.scss'

export const LevelMeter = ({
  value = 0,
  rank = 0,
  histgramData = [],
  minValue = 0,
  maxValue = 100
}) => {
  const level = (value - minValue) / (maxValue - minValue)
  const levelText =
    level < 0.2
      ? 'とても低い'
      : level < 0.4
      ? '低い'
      : level < 0.6
      ? 'まあまあ'
      : level < 0.8
      ? '高い'
      : 'とても高い'
  return (
    <div className={styles.container}>
      <div className={styles.levelText}>「{levelText}」</div>
      <div className={styles.rankText}>
        100人居たら
        <span className={styles.myRank}> {rank} 位 </span>
        です
      </div>
      <Histgram value={value} histgramData={histgramData} />
    </div>
  )
}

LevelMeter.propTypes = {
  value: PropTypes.number,
  rank: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  histgramData: PropTypes.array
}

export default LevelMeter
