import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './RabbitTypeText.module.scss'

export const RabbitTypeText = ({ rabbitType, precision = 0, thin = false }) => {
  const suffix =
    precision < 0.8 ? 'かも？' : precision < 0.85 ? 'です' : 'です！'
  return (
    <div className={ClassNames(styles.container, { [styles.thin]: thin })}>
      <div className={styles.typeText}>
        <div className={styles.title}>
          {rabbitType.name}
          <div className={styles.suffix}>{suffix}</div>
        </div>
        <div className={styles.description}>{rabbitType.text}</div>
      </div>
    </div>
  )
}

RabbitTypeText.propTypes = {
  rabbitType: PropTypes.object,
  precision: PropTypes.number,
  thin: PropTypes.bool
}

export default RabbitTypeText
