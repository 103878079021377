import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { AnnounceDoctor } from '../ui/AnnounceDoctor'
import LineFriendButton from '../ui/LineFriendButton'

import styles from './UnderConstruction.module.scss'

export function UnderConstruction() {
  const id = process.env.REACT_APP_LINE_FRIEND_ID
  const [lastClickAt, setLastClickAt] = useState(0)
  const unlock = () => {
    if (new Date() - lastClickAt < 300) {
      // Not implemented
    }
    setLastClickAt(new Date())
  }
  return (
    <div className={styles.container}>
      <Helmet>
        <title>準備中</title>
      </Helmet>
      <AnnounceDoctor
        text={`すまんのぉ。うさばら診断はまだ準備中じゃ。悪いんじゃが、9月以降にまた来てもらえるかの？\n\nLINEで友達登録をしてもらえれば、準備ができたタイミングでお知らせしますぞ\n\n`}
      >
        <LineFriendButton id={id} />
      </AnnounceDoctor>
      <div className={styles.b} onClick={e => unlock()} />
    </div>
  )
}
export default UnderConstruction
