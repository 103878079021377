import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendGaEvent } from '../../GaEvent'
import { saveRecord, setMetaData } from '../../stores/record'
import { ChatBaloonDoctor } from '../ui/ChatBaloon'

import styles from './Acceptability.module.scss'

export const Acceptability = ({ recordId }) => {
  const dispatch = useDispatch()
  const { data } = useSelector(state => state.record)
  const result = useSelector(state => state.result)

  const [posted, setPosted] = useState(false)

  const onClickButton = value => {
    dispatch(setMetaData({ key: 'acceptableResult', value })).then(() => {
      dispatch(saveRecord(recordId))
    })
    const label = value ? '納得できる' : '納得できない'
    sendGaEvent('post_feedback', 'feedback', label, result.rabbitType.typeId)
    setPosted(true)
  }

  const isAnswered =
    data.metadata != null
      ? data.metadata.find(el => el.key === 'acceptableResult')
      : false
  if ((isAnswered && !posted) || recordId == null) return <></>

  return (
    <div className={styles.container}>
      <ChatBaloonDoctor>
        <div className={styles.baloonContainer}>
          {posted ? (
            <>回答、感謝しますぞ！</>
          ) : (
            <>
              今回の診断結果の納得度はどうじゃったかの？
              <div className={styles.buttons}>
                <div
                  className={styles.disagree}
                  onClick={e => onClickButton(0)}
                >
                  納得できない
                </div>
                <div className={styles.agree} onClick={e => onClickButton(1)}>
                  納得できる
                </div>
              </div>
            </>
          )}
        </div>
      </ChatBaloonDoctor>
    </div>
  )
}
export default Acceptability

Acceptability.propTypes = {
  recordId: PropTypes.string
}
