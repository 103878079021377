import classNames from 'classnames'
import * as d3 from 'd3'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import styles from './TimelineChart.module.scss'

export function TimelineChart({ data = [], thin }) {
  const graphRef = useRef()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [svg, setSvg] = useState(null)

  useEffect(() => {
    const node = graphRef.current
    d3.select(node).selectAll('*').remove()
    const w = node.getBoundingClientRect().width
    const h = node.getBoundingClientRect().height
    const s = d3.select(node).attr('height', h).attr('width', w)
    s.append('g').attr('class', 'scale-x')
    s.append('g').attr('class', 'scale-y')
    s.append('g').attr('class', 'data-path')
    s.append('g').attr('class', 'data-plot')
    s.append('g').attr('class', 'curve-path')
    setWidth(w)
    setHeight(h)
    setSvg(s)
  }, [data])

  useEffect(() => {
    if (svg == null || width === 0 || data.length === 0) return
    const margin = 20
    const xStart = () => margin
    const yStart = () => height - margin
    const xEnd = () => width - margin
    const yEnd = () => 0

    const xScale = d3
      .scaleTime()
      .domain([d3.min(data, d => d.date), d3.max(data, d => d.date)])
      .range([xStart(), xEnd()])
    const yScale = d3
      .scaleLinear()
      .domain([d3.min(data, d => d.value) - 5, d3.max(data, d => d.value) + 5])
      .range([yStart(), yEnd()])
    const format = d3.timeFormat('%m/%d')
    const axisx = d3
      .axisBottom(xScale)
      .ticks(thin ? 2 : 4)
      .tickFormat(format)
    const axisy = d3
      .axisLeft(yScale)
      .ticks(5)
      .tickSize(-width + margin * 2)

    const scalex = svg.select('.scale-x')
    scalex.exit().remove()
    scalex
      .attr('transform', 'translate(' + 0 + ',' + yStart() + ')')
      .call(axisx)
      .attr('class', styles.axisX)
    const scaley = svg.select('.scale-y')
    scaley.exit().remove()
    scaley
      .attr('transform', () => 'translate(' + xStart() + ',' + 0 + ')')
      .call(axisy)
      .attr('class', styles.axisY)

    const line = d3
      .line()
      .x(d => xScale(d.date))
      .y(d => yScale(d.value))

    const dataPath = svg.select('.data-path').selectAll('path').data([data])
    dataPath.exit().remove()
    dataPath
      .enter()
      .append('path')
      .attr('class', styles.dataPath)
      .attr('d', d => line(d))

    const dataPlot = svg.select('.data-plot').selectAll('circle').data(data)
    dataPlot.exit().remove()
    dataPlot
      .enter()
      .append('circle')
      .attr('cx', d => xScale(d.date))
      .attr('cy', d => yScale(d.value))
      .attr('class', styles.dataPlot)
      .attr('r', thin ? 2 : 3)
  }, [svg, data, height, width, thin])

  return (
    <div className={classNames(styles.container, { [styles.thin]: thin })}>
      <svg ref={graphRef} className={styles.graph} />
    </div>
  )
}

export default TimelineChart

TimelineChart.propTypes = {
  data: PropTypes.array,
  thin: PropTypes.bool
}
