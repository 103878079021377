import { createSlice } from '@reduxjs/toolkit'

import { analyze, getHistogram } from '../logic/'

const makeRanking = copings =>
  copings.map(e => ({
    name: e.name,
    value: Math.abs(e.zAndAllMean)
  }))

export const result = createSlice({
  name: 'result',
  initialState: {
    histgramData: [],
    precision: 0,
    rankTop5: [],
    rankWorst5: [],
    contentsTop5: [],
    rabbitType: {},
    stressLevel: 0,
    stressLevelRank: 0,
    typeScore: [[]]
  },
  reducers: {
    makeResult: (state, action) => {
      if (action.payload == null) return
      const { logicVersion, scriptVersion, input } = action.payload
      const result = analyze({ logicVersion, scriptVersion, data: input })
      // console.log(result)
      const {
        coping,
        copingContents,
        precision,
        sixScales,
        stressLevel,
        stressLevelRank,
        type
      } = result
      coping.sort((a, b) => b.zAndAllMean - a.zAndAllMean)
      const contents = copingContents.filter(c => !isNaN(c.cos))
      contents.sort((a, b) => b.cos - a.cos)
      state.rankTop5 = makeRanking(coping.slice(0, 5))
      state.rankWorst5 = makeRanking(coping.slice(-5))
      state.rankWorst5.sort((a, b) => b.value - a.value)
      state.contentsTop5 = contents.slice(0, 5)
      state.stressLevel = stressLevel
      state.stressLevelRank = stressLevelRank
      state.rabbitType = type
      state.precision = precision
      state.histgramData = getHistogram(action.payload).map(el => ({
        x: el.max,
        y: el.count
      }))
      state.typeScore = [
        [
          { name: '誰かと会う', value: sixScales[0].zScore },
          { name: '解決に務める', value: sixScales[1].zScore },
          { name: '自分を責める', value: sixScales[2].zScore },
          { name: '状況を分析する', value: sixScales[3].zScore },
          { name: '意識をそらす', value: sixScales[4].zScore },
          { name: '楽観視する', value: sixScales[5].zScore }
        ]
      ]
    }
  }
})
export const { makeResult } = result.actions

export default result.reducer
