import PropTypes from 'prop-types'
import React from 'react'

import t011 from '../../assets/images/type/rabbit_type_1/rabbit_type_1_1.svg'
import t012 from '../../assets/images/type/rabbit_type_1/rabbit_type_1_2.svg'
import t013 from '../../assets/images/type/rabbit_type_1/rabbit_type_1_3.svg'
import t021 from '../../assets/images/type/rabbit_type_2/rabbit_type_2_1.svg'
import t022 from '../../assets/images/type/rabbit_type_2/rabbit_type_2_2.svg'
import t023 from '../../assets/images/type/rabbit_type_2/rabbit_type_2_3.svg'
import t031 from '../../assets/images/type/rabbit_type_3/rabbit_type_3_1.svg'
import t032 from '../../assets/images/type/rabbit_type_3/rabbit_type_3_2.svg'
import t033 from '../../assets/images/type/rabbit_type_3/rabbit_type_3_3.svg'
import t041 from '../../assets/images/type/rabbit_type_4/rabbit_type_4_1.svg'
import t042 from '../../assets/images/type/rabbit_type_4/rabbit_type_4_2.svg'
import t043 from '../../assets/images/type/rabbit_type_4/rabbit_type_4_3.svg'
import t051 from '../../assets/images/type/rabbit_type_5/rabbit_type_5_1.svg'
import t052 from '../../assets/images/type/rabbit_type_5/rabbit_type_5_2.svg'
import t053 from '../../assets/images/type/rabbit_type_5/rabbit_type_5_3.svg'
import t061 from '../../assets/images/type/rabbit_type_6/rabbit_type_6_1.svg'
import t062 from '../../assets/images/type/rabbit_type_6/rabbit_type_6_2.svg'
import t063 from '../../assets/images/type/rabbit_type_6/rabbit_type_6_3.svg'
import t071 from '../../assets/images/type/rabbit_type_7/rabbit_type_7_1.svg'
import t072 from '../../assets/images/type/rabbit_type_7/rabbit_type_7_2.svg'
import t073 from '../../assets/images/type/rabbit_type_7/rabbit_type_7_3.svg'
import t081 from '../../assets/images/type/rabbit_type_8/rabbit_type_8_1.svg'
import t082 from '../../assets/images/type/rabbit_type_8/rabbit_type_8_2.svg'
import t083 from '../../assets/images/type/rabbit_type_8/rabbit_type_8_3.svg'
import t091 from '../../assets/images/type/rabbit_type_9/rabbit_type_9_1.svg'
import t092 from '../../assets/images/type/rabbit_type_9/rabbit_type_9_2.svg'
import t093 from '../../assets/images/type/rabbit_type_9/rabbit_type_9_3.svg'
import t101 from '../../assets/images/type/rabbit_type_10/rabbit_type_10_1.svg'
import t102 from '../../assets/images/type/rabbit_type_10/rabbit_type_10_2.svg'
import t103 from '../../assets/images/type/rabbit_type_10/rabbit_type_10_3.svg'
import t111 from '../../assets/images/type/rabbit_type_11/rabbit_type_11_1.svg'
import t112 from '../../assets/images/type/rabbit_type_11/rabbit_type_11_2.svg'
import t113 from '../../assets/images/type/rabbit_type_11/rabbit_type_11_3.svg'
import t121 from '../../assets/images/type/rabbit_type_12/rabbit_type_12_1.svg'
import t122 from '../../assets/images/type/rabbit_type_12/rabbit_type_12_2.svg'
import t123 from '../../assets/images/type/rabbit_type_12/rabbit_type_12_3.svg'
import styles from './RabbitTypeImage.module.scss'

const typeImages = [
  [t011, t012, t013],
  [t021, t022, t023],
  [t031, t032, t033],
  [t041, t042, t043],
  [t051, t052, t053],
  [t061, t062, t063],
  [t071, t072, t073],
  [t081, t082, t083],
  [t091, t092, t093],
  [t101, t102, t103],
  [t111, t112, t113],
  [t121, t122, t123]
]

export const RabbitTypeImage = ({ typeId = null, precision = 0 }) => {
  const level = precision < 0.8 ? 0 : precision < 0.85 ? 1 : 2
  if (typeId == null) return <div className={styles.container} />
  const type = typeId >= 1 && typeId <= 12 ? typeId : 1
  return (
    <div className={styles.container}>
      <img alt='typeImg' src={typeImages[type - 1][level]} />
    </div>
  )
}

RabbitTypeImage.propTypes = {
  typeId: PropTypes.number,
  precision: PropTypes.number
}

export default RabbitTypeImage
