import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import contentsData from '../logic/v1/copingContentsData.json'

export const FILTER_NONE = 'none'
export const FILTER_PROBLEM_FOCUS = 'problemForcused'
export const FILTER_SOCIAL_SUPPORT = 'socialSupport'
export const FILTER_SELF_DISCLOSURE = 'selfDisclosure'
export const FILTER_POSITIVE = 'positive'
export const FILTER_DISTRACTION = 'distraction'
export const FILTER_RELAXATION = 'relaxation'

export const fetchArticle = createAsyncThunk(
  'article/fetchArticle',
  async (args, thunkAPI) => {
    const response = await fetch(`/scripts/article/article_${args}.json`)
    const data = await response.json()
    return { article: data }
  }
)

const deriveFilteredList = filter => {
  if (filter !== FILTER_NONE) {
    return contentsData.data.filter(
      article => article.styleVariables[filter] === 1
    )
  } else {
    return contentsData.data
  }
}

export const article = createSlice({
  name: 'article',
  initialState: {
    article: {},
    articleListFilter: FILTER_NONE,
    articleList: [],
    newArticles: []
  },
  reducers: {
    setArticleListFilter: (state, action) => {
      state.articleListFilter = action.payload
      state.articleList = deriveFilteredList(action.payload)
    },
    updateArticleList: state => {
      state.articleList = deriveFilteredList(state.articleListFilter)
      state.newArticles = _.cloneDeep(contentsData.data)
        .sort((a, b) => b.copingContentsId - a.copingContentsId)
        .slice(0, 5)
    }
  },
  extraReducers: {
    [fetchArticle.fulfilled]: (state, action) => {
      state.article = action.payload.article
    }
  }
})
export const { setArticleListFilter, updateArticleList } = article.actions

export default article.reducer
