// Takram Confidential
// Copyright (C) 2019-Present Takram

// import PropTypes from 'prop-types'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import {
  FILTER_DISTRACTION,
  FILTER_NONE,
  FILTER_POSITIVE,
  FILTER_PROBLEM_FOCUS,
  FILTER_RELAXATION,
  FILTER_SELF_DISCLOSURE,
  FILTER_SOCIAL_SUPPORT,
  setArticleListFilter,
  updateArticleList
} from '../../stores/article'
import { Button } from '../ui/Button'
import { ListCatalog } from './ListCatalog'

import styles from './List.module.scss'

const useButtonStyles = makeStyles(theme => ({
  root: { width: 240 }
}))
export default function List() {
  const dispatch = useDispatch()
  const { articleList, articleListFilter, newArticles } = useSelector(
    state => state.article
  )
  const [displayNum, setDisplayNum] = useState(5)

  const buttons = [
    { filter: FILTER_PROBLEM_FOCUS, name: '問題を解決' },
    { filter: FILTER_SOCIAL_SUPPORT, name: '誰かと解決' },
    { filter: FILTER_SELF_DISCLOSURE, name: '打ち明け対処' },
    { filter: FILTER_POSITIVE, name: 'とらえ直し' },
    { filter: FILTER_DISTRACTION, name: '気分転換' },
    { filter: FILTER_RELAXATION, name: 'リラックス' }
  ]

  useEffect(() => {
    dispatch(setArticleListFilter(FILTER_NONE))
    dispatch(updateArticleList())
  }, [dispatch])

  const onClickFilter = filter => {
    if (filter === articleListFilter) {
      dispatch(setArticleListFilter(FILTER_NONE))
    } else {
      dispatch(setArticleListFilter(filter))
    }
    setDisplayNum(5)
  }

  const clickMore = () => {
    setDisplayNum(displayNum + 5)
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <title>アドバイス記事</title>
      </Helmet>
      <motion.div
        animate={{ opacity: 1.0 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h3>新着記事</h3>
        <ListCatalog articles={newArticles} />
        <br />
        <h3>カテゴリーで探す</h3>
        <div className={styles.filterGrid}>
          {buttons.map(b => (
            <div
              className={classNames(styles.filter, {
                [styles.selected]: b.filter === articleListFilter
              })}
              onClick={e => onClickFilter(b.filter)}
              key={b.name}
            >
              <div className={styles.filterName}>{b.name}</div>
            </div>
          ))}
        </div>
        <ListCatalog articles={articleList.slice(0, displayNum)} />
        <br />
        <Button
          text='more'
          size='small'
          color='secondary'
          disabled={displayNum > articleList.length}
          onClick={e => clickMore()}
          classes={useButtonStyles()}
        />
        <br />
      </motion.div>
    </div>
  )
}

List.propTypes = {}
