import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const STORE_KEY = 'USABARASHI'

export const setIsFirstLaunch = createAsyncThunk(
  'app/setIsFirstLaunch',
  async (args, thunkAPI) => ({ isFirstLaunch: args })
)

export const setLatestRecordId = createAsyncThunk(
  'app/setLatestRecordId',
  async (args, thunkAPI) => ({ latestRecordId: args })
)

export const setIsLoggingIn = createAsyncThunk(
  'app/setIsLoggingIn',
  async (args, thunkAPI) => ({ isLoggingIn: args })
)

const LOCAL_STORE_INITIAL_STATE = {
  isFirstLaunch: true,
  isLoggingIn: false,
  latestRecordId: null
}

export const localStore = createSlice({
  name: 'localStore',
  initialState: _.cloneDeep(LOCAL_STORE_INITIAL_STATE),
  reducers: {
    loadState: state => {
      const data = localStorage.getItem(STORE_KEY)
      if (data == null) return
      const {
        isFirstLaunch,
        latestRecordId,
        isLoggingIn,
        recordId
      } = JSON.parse(data)
      const rid =
        latestRecordId == null
          ? recordId == null // For alpha release user
            ? null
            : recordId
          : latestRecordId
      state.isFirstLaunch = isFirstLaunch ?? state.isFirstLaunch
      state.latestRecordId = rid ?? state.latestRecordId
      state.isLoggingIn = isLoggingIn ?? state.isLoggingIn
    },
    saveState: state => {
      localStorage.setItem(STORE_KEY, JSON.stringify(state))
    },
    resetState: state => {
      state.isFirstLaunch = LOCAL_STORE_INITIAL_STATE.isFirstLaunch
      state.latestRecordId = LOCAL_STORE_INITIAL_STATE.latestRecordId
      localStorage.setItem(STORE_KEY, JSON.stringify(state))
    }
  },
  extraReducers: {
    [setIsFirstLaunch.fulfilled]: (state, action) => {
      state.isFirstLaunch = action.payload.isFirstLaunch
    },
    [setLatestRecordId.fulfilled]: (state, action) => {
      state.latestRecordId = action.payload.latestRecordId
    },
    [setIsLoggingIn.fulfilled]: (state, action) => {
      state.isLoggingIn = action.payload.isLoggingIn
    }
  }
})

export const { loadState, saveState, resetState } = localStore.actions

export default localStore.reducer
