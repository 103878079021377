import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './PrecisionScale.module.scss'

const scaleData = [
  {
    threshold: 0.74,
    scale: 'まだまだ足りない'
  },
  {
    threshold: 0.75,
    scale: 'ちょっと足りない'
  },
  {
    threshold: 0.78,
    scale: 'まあまあ'
  },
  {
    threshold: 0.8,
    scale: 'それなり'
  },
  {
    threshold: 0.83,
    scale: 'いい感じ！'
  },
  {
    threshold: 0.85,
    scale: '足りてきた！'
  },
  {
    threshold: 1.0,
    scale: '十分！'
  }
]
export default function PrecisionScale({ precision = 0.0 }) {
  const data = scaleData.find(el => precision < el.threshold)
  return (
    <span className={styles.container}>
      {data.scale}
      <div
        className={classNames(styles.accuracy, {
          [styles.lv1]: precision > 0,
          [styles.lv2]: precision > 0.78,
          [styles.lv3]: precision > 0.83,
          [styles.lv4]: precision > 0.85
        })}
      />
    </span>
  )
}

PrecisionScale.propTypes = {
  precision: PropTypes.number
}
