import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendGaEvent } from '../../GaEvent'
import { fetchRecord } from '../../stores/record'
import { makeResult } from '../../stores/result'
import AppError from '../app/AppError'
import LoginRandomGuide from '../app/LoginRandomGuide'
import ListCatalog from '../article/ListCatalog'
import AccountGuide from '../result/AccountGuide'
import RabbitTypeImage from '../result/RabbitTypeImage'
import RabbitTypeText from '../result/RabbitTypeText'
import StressTimeline from '../result/StressTimeline'
import Button from '../ui/Button'
import AlphaNotice from './AlphaNotice'

import styles from './Home.module.scss'

export function HomeAfterAnalytics({ recordId }) {
  const dispatch = useDispatch()
  const { contentsTop5, precision, rabbitType } = useSelector(
    state => state.result
  )
  const { fetchFailed } = useSelector(state => state.record)

  useEffect(() => {
    dispatch(fetchRecord(recordId)).then(action => {
      dispatch(makeResult(action.payload))
    })
  }, [dispatch, recordId])

  if (fetchFailed) return <AppError />

  return (
    <div className={styles.container}>
      <div className={styles.cardBlock}>
        <h3>あなたの前回の診断結果</h3>
        <div className={styles.rabbitTable}>
          <RabbitTypeImage
            typeId={rabbitType.typeId}
            precision={precision}
            size={200}
          />
          <StressTimeline thin={true} />
        </div>
        <div className={styles.rabbitText}>
          <RabbitTypeText rabbitType={rabbitType} precision={precision} thin />
        </div>

        <div className={styles.actions}>
          <Button
            text='診断結果の詳細を見る'
            color='secondary'
            href={`/result`}
            fullWidth
          />
          <Button
            text='今日の状態を診断する'
            href={`/analysis/`}
            size='small'
            color='primary'
            fullWidth
            onClick={() =>
              sendGaEvent('go_to_analysis', 'analysis', 'ホーム画面')
            }
          />
        </div>
      </div>

      <div className={styles.block}>
        <AccountGuide />
      </div>
      <div className={styles.block}>
        <h2>あなたのうさの晴らし方</h2>
        <ListCatalog articles={contentsTop5} />
      </div>
      <div className={styles.block}>
        <h3>うさばら診断とは？</h3>
        <p>
          あなたの状態を医学統計データをもとに分析し、あなたに合った解消法を紹介します。周りの人に言いにくく、つい溜め込みがちだったストレスについて、もっと手軽に知って発散でき、気軽に話せる世の中になってほしい。ちょっと大げさかもしれませんが、そんな未来を目指したサービスです。
        </p>
      </div>
      <div className={styles.block}>
        <AlphaNotice />
      </div>
      <LoginRandomGuide />
    </div>
  )
}
export default HomeAfterAnalytics

HomeAfterAnalytics.propTypes = {
  recordId: PropTypes.string
}
