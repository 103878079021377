import React from 'react'

import styles from './AlphaNotice.module.scss'

export default function AlphaNotice() {
  return (
    <div className={styles.container}>
      <h3>本サービスについて</h3>
      <p>
        本サービスは現在アルファ版としてテスト公開しているため、予告なく公開を停止する場合がございます。予めご了承ください。
      </p>
    </div>
  )
}
