// import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Button } from '../ui/Button'

import styles from './AnswerSelect.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  containedPrimary: {
    backgroundColor: '#E8F7FF',
    color: '#3DA5E0',
    borderWidth: 1,
    borderColor: '#90D7FF',
    '&:hover': {
      backgroundColor: '#90D7FF'
    }
  }
}))

const normalize = (val, min, max) => (val - min) / (max - min)
const unnormalize = (val, min, max) => parseInt(val * (max - min) + min)

export const AnswerSelect = ({
  onChange = () => {},
  question = {},
  record = {}
}) => {
  const classes = useStyles()
  const isSingle = question.questionType === 'single_select'
  const itemNum = question.items.length
  if (!isSingle)
    record = {
      ...question.items.reduce((hash, i) => ({ ...hash, [i.itemId]: 0 }), {}),
      ...record
    }
  const onClickButton = clickItem => {
    onChange(
      isSingle
        ? {
            [clickItem.itemId]: normalize(clickItem.value, 1, itemNum)
          }
        : question.items.reduce(
            (hash, item) => ({
              ...hash,
              [item.itemId]:
                item.itemId === clickItem.itemId
                  ? 1 - record[item.itemId]
                  : record[item.itemId]
            }),
            {}
          )
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        {question.items.map(item => (
          <div className={styles.button} key={item.itemId + item.text}>
            <Button
              onClick={e => onClickButton(item)}
              text={item.text}
              size='small'
              color={
                (isSingle &&
                  unnormalize(record[item.itemId], 1, itemNum) ===
                    item.value) ||
                (!isSingle && record[item.itemId] === 1)
                  ? 'primary'
                  : 'default'
              }
              classes={classes}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

AnswerSelect.propTypes = {
  onChange: PropTypes.func,
  question: PropTypes.object,
  record: PropTypes.object
}
