import { createSlice } from '@reduxjs/toolkit'

import firebase from '../Firebase'

let subscribeHistoryBuf = null

export const subscribeHistory = user => {
  return async dispatch => {
    const db = firebase
      .firestore()
      .collection('records')
      .where('userId', '==', user.uid)
      .orderBy('createdAt', 'desc')
    subscribeHistoryBuf = db.onSnapshot(snapshot => {
      const data = snapshot.docs.map(doc => doc.data())
      const latestId = snapshot.docs.length === 0 ? null : snapshot.docs[0].id
      dispatch(updateHistory({ data, latestId }))
    })
  }
}

export const deleteHistory = userId => {
  return async dispatch => {
    const db = firebase
      .firestore()
      .collection('records')
      .where('userId', '==', userId)
    await db.get().then(querySnapshot => {
      querySnapshot.forEach(doc => doc.ref.delete())
    })
  }
}

export const recordHistory = createSlice({
  name: 'recordHistory',
  initialState: {
    data: [],
    fetchFailed: false,
    latestRecordId: null
  },
  reducers: {
    updateHistory: (state, action) => {
      state.data = action.payload.data
      state.latestRecordId = action.payload.latestId
    },
    unsubscribeHistory: state => {
      subscribeHistoryBuf()
      state.data = []
      state.latestRecordId = null
    }
  }
})

export const { updateHistory, unsubscribeHistory } = recordHistory.actions

export default recordHistory.reducer
