import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Hashids from 'hashids'
import _ from 'lodash'

import firebase from '../Firebase'

const USER_INITIAL_STATE = {
  auth: {
    displayName: null,
    email: null,
    uid: null
  },
  data: {
    lineUserId: null,
    syncCode: '',
    favoriteArticleIds: []
  }
}

export const toggleFavoriteArticle = createAsyncThunk(
  'user/addFavoriteArticle',
  async (articleId, thunkAPI) => {
    const { auth, data } = thunkAPI.getState().user
    if (auth.uid == null) return
    const newData = _.cloneDeep(data)
    newData.favoriteArticleIds = _.xor(newData.favoriteArticleIds, [articleId])
    const ref = firebase.firestore().collection('users').doc(auth.uid)
    await ref.update({ ...newData })
  }
)

const generateSyncCode = userId => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
  const hashid = new Hashids(userId, 8, chars)
  return hashid.encode(1).match(/.{4}/g).join('-')
}

let subscribeUserBuf = null

export const subscribeUser = user => {
  return async dispatch => {
    const ref = firebase.firestore().collection('users')
    // Add user if not exits
    const docChk = await ref.doc(user.uid).get()
    if (!docChk.exists) {
      const newUser = {
        lineUserId: null,
        syncCode: generateSyncCode(user.uid),
        favoriteArticleIds: []
      }
      await docChk.ref.set(newUser)
    }
    // Then subscribe
    const db = ref.doc(user.uid)
    subscribeUserBuf = db.onSnapshot(doc => {
      const data = { ..._.cloneDeep(USER_INITIAL_STATE.data), ...doc.data() }
      dispatch(updateData({ user, data }))
    })
  }
}

export const user = createSlice({
  name: 'user',
  initialState: _.cloneDeep(USER_INITIAL_STATE),
  reducers: {
    unsubscribeUser: state => {
      subscribeUserBuf()
      state.auth = _.cloneDeep(USER_INITIAL_STATE.auth)
      state.data = _.cloneDeep(USER_INITIAL_STATE.data)
    },
    updateData: (state, action) => {
      state.auth = action.payload.user
      state.data = action.payload.data
    }
  }
})
export const { unsubscribeUser, updateData } = user.actions

export default user.reducer
