import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import firebase from '../Firebase'
import { analyze } from '../logic/'

export const fetchRecord = createAsyncThunk(
  'record/fetchRecord',
  async (recordId, thunkAPI) => {
    const db = firebase.firestore().collection('records')
    const doc = await db.doc(recordId).get()
    if (doc.exists) {
      return doc.data()
    } else {
      console.warn('Could not find a record ID : ', recordId)
      return null
    }
  }
)

export const addRecord = createAsyncThunk(
  'record/addRecord',
  async (record, thunkAPI) => {
    const db = firebase.firestore().collection('records')
    const { auth } = thunkAPI.getState().user
    let id = ''
    await db
      .add({
        ...INITIAL_RECORD_DATA,
        ...record,
        userId: auth.uid,
        interface: 'WEB_SITE'
      })
      .then(docRef => {
        id = docRef.id
      })
    return id
  }
)

export const setMetaData = createAsyncThunk(
  'record/setMetaData',
  async (data, thunkAPI) => data
)

export const saveRecord = createAsyncThunk(
  'record/saveRecord',
  async (recordId, thunkAPI) => {
    const { data } = thunkAPI.getState().record
    const ref = firebase.firestore().collection('records').doc(recordId)
    ref.get().then(doc => ref.update(data))
  }
)

// Assign User ID to records created when not logged in
export const assignUserId2LocalRecord = createAsyncThunk(
  'record/assignUserId2LocalRecord',
  async (userId, thunkAPI) => {
    const { latestRecordId } = thunkAPI.getState().localStore
    if (userId == null || latestRecordId == null) return
    const ref = firebase.firestore().collection('records').doc(latestRecordId)
    ref.get().then(doc => {
      if (doc.exists) {
        const record = doc.data()
        record.userId = userId // Force Assign
        ref.update(record)
      }
    })
  }
)

export const makeRecordData = (script, input, answeredItemIds) => {
  const result = analyze({
    logicVersion: script.logicVersion,
    scriptVersion: script.scriptVersion,
    data: Object.entries(input).map(([k, v]) => ({
      itemId: k,
      value: v
    }))
  })
  const recordResult = {
    precision: result.precision,
    typeId: result.type.typeId,
    stressLevel: result.stressLevel,
    stressLevelRank: result.stressLevelRank,
    sixScales: result.sixScales.map(s => ({
      scaleId: s.scaleId,
      zScore: s.zScore
    }))
  }
  return {
    ...INITIAL_RECORD_DATA,
    createdAt: new Date(),
    userId: null,
    logicVersion: script.logicVersion,
    scriptVersion: script.scriptVersion,
    input: Object.entries(input).map(([k, v]) => ({
      itemId: k,
      value: v
    })),
    answeredItemIds,
    result: recordResult
  }
}

export const clearRecord = createAsyncThunk(
  'record/clearRecord',
  async (args, thunkAPI) => {}
)

const INITIAL_RECORD_DATA = {
  createdAt: new Date(),
  userId: null,
  logicVersion: 1,
  scriptVersion: 1,
  input: [],
  result: [],
  answeredItemIds: [],
  interface: '',
  metadata: []
}

export const record = createSlice({
  name: 'record',
  initialState: {
    data: _.cloneDeep(INITIAL_RECORD_DATA),
    fetchFailed: false
  },
  extraReducers: {
    [fetchRecord.fulfilled]: (state, action) => {
      if (action.payload == null) {
        state.fetchFailed = true
        return
      }
      state.data = { ...state.data, ...action.payload }
      state.fetchFailed = false
    },
    [addRecord.fulfilled]: (state, action) => {
      console.log('Add record', action.payload)
    },
    [addRecord.saveRecord]: (state, action) => {
      console.log('Save record', action.payload)
    },
    [clearRecord.fulfilled]: (state, action) => {
      state.data = _.cloneDeep(INITIAL_RECORD_DATA)
      state.fetchFailed = false
    },
    [setMetaData.fulfilled]: (state, action) => {
      const { key, value } = action.payload
      const d = state.data.metadata.find(el => el.key === key)
      if (d == null) {
        state.data.metadata.push({ key, value })
      } else {
        d.value = value
      }
    }
  }
})

export default record.reducer
