import samplingData from './samplingData.json'

export const getHistogram = ({ div = 20 }) => {
  const samples = samplingData.data.map(element => 50 - 10 * element)
  const range = samples.reduce(
    (out, current) => {
      const min = current < out.min ? current : out.min
      const max = current > out.max ? current : out.max
      return { min, max }
    },
    { min: 1000, max: -1000 }
  )
  const ranges = []
  const diff = (range.max - range.min) / div
  for (let i = 0; i < div; i++) {
    const min = diff * i + range.min
    const max = diff * (i + 1) + range.min
    ranges.push({ min, max, count: 0 })
  }
  let histogram = samples.reduce((accum, current) => {
    let index = ranges.findIndex(
      ({ min, max }) => current >= min && current < max
    )
    if (current === range.max) index = div - 1
    if (accum[index] != null) accum[index].count++
    return accum
  }, ranges)
  const countMax = histogram.reduce(
    (out, current) => (current.count > out ? current.count : out),
    -1000
  )
  histogram = histogram.map(element => ({
    ...element,
    normCount: element.count / countMax
  }))
  return histogram
}
