import React from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './PrivacyPolicy.module.scss'

export default function PrivacyPolicy() {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>プライバシーポリシー</title>
      </Helmet>
      <h1>PrivacyPolicy</h1>
      <p>
        本プライバシーポリシーは、お客様が株式会社Journey（以下「弊社」といいます。）のウェブサイト「Journey（wellness
        journey）」、モバイルアプリケーション、その他のオンラインおよびオフラインのサービス（以下、総称して「本サービス」といいます。）にアクセスし、ご利用になる場合、弊社のお客様サービス部にお問い合わせをされる場合、またはSNSその他の方法で弊社にお問い合わせをされる場合に、弊社がお客様の情報を収集、使用または開示する方法および過程をご説明するものです。
        弊社は、本プライバシーポリシーを改訂することがあります。弊社が本プライバシーポリシーを改訂した場合には、本プライバシーポリシーの冒頭に記載する日付を改訂し、弊社のウェブサイトのトップページにご報告を追加し、またはお客様に通知を送付する等の方法により、お客様に通知するものとします。また、お客様が本サービスにアクセスされる場合、または弊社との間でその他のやりとりをされる場合には、本プライバシーポリシーを確認し、弊社のデータに関するプラクティスおよびお客様が選択・変更可能な項目についてご確認くださいますようお願いいたします。
      </p>
      <h3>1. お客様の情報の収集方法に関する方針</h3>
      <p>弊社は、以下の方法でお客様の情報を収集します。</p>
      <p>
        <b>
          i. お客様が弊社に提供した場合、または弊社が取得することに同意した場合
        </b>
        お客様が本サービスに登録した場合、または本サービスをご利用になった場合は、お客様が自発的にお客様の情報を弊社に提供することにご同意いただいたこととなります。ここにいうお客様の情報には、お客様のお名前、電子メールアドレスその他の情報が含まれます。また、その他にも、弊社がお客様の情報にアクセスすることにご同意いただいたこととなる場合があります。例えば、お客様が、お客様のFacebook、Twitter、Google等上のアカウント（以下、総称して「SNSアカウント」といいます。）を本サービス上のお客様の「Journey」アカウントにリンクした場合、弊社は、SNSアカウントから情報（お客様の友達リストや連絡先などの情報や、iiの「クリックストリームデータ」に定める情報など）を収集することがあります。以上の方法によって弊社が取得する情報の内容は、他社から提供される各サービスにおけるデータの取扱方法や当該サービスにおけるお客様の設定によって異なりますので、必ず各サービスのプライバシーポリシーや当該サービスにおけるお客様の設定をご確認ください。
      </p>
      <p>
        <b>ii. 収集する情報</b>
        お客様が、ウェブサイト、モバイルアプリケーションその他のインターネットサービスをご利用になる場合、一般的に、自動的に作成・記録される技術情報があります。お客様が本サービスをご利用になる場合も同様です。その際、弊社が収集する情報は以下の通りです。
      </p>
      <ul>
        <li>
          ログデータ：本サービスをご利用になる際、弊社のサーバーは自動的にお客様の情報（以下、本iiにおいて「ログデータ」といいます。）を記録します。ログデータには、お客様がウェブサイトをご利用になる際にお客様のブラウザが送信する情報や、お客様がモバイルアプリケーションをご利用になる際にお客様のモバイルアプリケーションが送信する情報が含まれます。また、ログデータには、お客様のインターネットプロトコルアドレス、ブラウザの種類および設定、お客様がウェブサイトやアプリで視聴し、お気に入りリストに追加した本サービスの動画その他のコンテンツに関する情報ならびにお客様が弊社サービスを利用した時間などが含まれます。
        </li>
        <li>
          デバイス情報：ログデータに加えて、お客様が本サービスをご使用中のデバイスに関する情報（デバイスの種類、使用中のオペレーティングシステム、デバイスの設定、デバイス固有の識別子、クラッシュデータなど）を収集する場合があります。弊社が、これらの情報の一部または全部を収集できるか否かは、お客様がご使用中のデバイスの機種と設定によります。例えば、お客様がご使用になっているPCの端末、またはiPhoneもしくはAndroidのいずれかにより、収集可能な情報が異なります。弊社がお客様の端末から収集可能な情報につきましては、当該端末のメーカーやソフトウェアプロバイダーのプライバシーポリシーもご確認ください。
        </li>
        <li>
          クリックストリームデータ：お客様が本サービスをご利用になる際、弊社は、お客様の関心等について理解を深める目的で、お客様の活動情報（どの動画その他のコンテンツをクリックしたか、各動画に対するお客様の関心度、どの動画をお気に入りリストに追加したかなどの情報）を収集します。例えば、お客様が動画をお気に入りリストに追加した場合、その情報に基づいて、弊社はお客様が同じカテゴリーの動画を好まれることを予測し、お客様に類似の動画をお勧めする場合があります。また、お客様が
          SNS アカウントを本サービスにリンクした場合、SNS
          アカウントから収集可能な情報（お客様の学歴や職歴など）に基づき、弊社は、お客様が好まれる動画について同様の予測をする場合があります。
        </li>
        <li>
          購買データ：お客様が弊社のサービスを通じて購入した場合、弊社は分析および顧客リスト作成の目的で、お客様の氏名、電子メールアドレス、住所（郵便番号および居住国を含む）および電話番号などの購入に関連する情報を収集します。また、お客様の購入に関連して、注文履歴及びクッキーを収集します。
        </li>
      </ul>
      <h3>2. お客様の情報を収集する目的および利用方法に関する方針</h3>
      <p>
        弊社は、本サービスの提供、本サービスの向上、本サービスの開発および弊社とお客様の保護を目的として、お客様から収集した情報を使用します。例えば、本サービスの異なるバージョンをどのくらいの頻度で利用しているかを記録し、どのバージョンが優れているかを把握します。
        また、弊社は、お客様から収集した情報を、以下のようにお客様用にカスタマイズしてコンテンツを提供するために使用します。
      </p>
      <ul>
        <li>
          お客様に気に入っていただけると予測される動画その他のコンテンツの提案。例えば、弊社が、お客様は日本文化に興味があると予測した場合、そのジャンルの動画その他のコンテンツを提供する場合があります。
          加えて、弊社は、お客様から収集した情報を以下の目的で使用します。
        </li>
        <li>
          最新情報（動画その他のコンテンツが更新された場合等、本サービス上で特定の活動があった場合）、ニュースレター、マーケティング資料、その他お客様のご関心と合致する可能性のある情報の送信。例えば、お客様の通知設定に応じて、お客様のご関心と合致すると予測される動画その他のコンテンツを含むアップデートを毎週お送りする場合があります。なお、お客様は、下記の「お問い合わせ先」を通じて、配信停止の依頼をしていただくことにより、配信を停止することができます。
        </li>
        <li>
          お客様の連絡先およびお友達による本サービス上でのお客様アカウントの発見の促進。例えば、お客様がFacebookアカウントを利用して本サービスを登録した場合、お客様のFacebookアカウント上の友達リストにいるお友達が、本サービス上のお客様の「Journey」アカウントを容易に発見できるようにする場合があります。また、お客様の電子メールアドレスを利用して、本サービス上でお客様のアカウントを検索できるようにする場合があります。
        </li>
        <li>お客様のご質問やご意見への回答。</li>
      </ul>
      <h3>3. 情報共有の方法・時期に関する方針</h3>
      <p>弊社は、以下の場合にお客様の個人情報を共有する場合があります。</p>
      <ul>
        <li>
          弊社は、本プライバシーポリシーを遵守した上で、個人情報の取扱いを第三者たる企業または個人に委託する場合があります。例えば、弊社はスパムの識別能力の改善を目的として、セキュリティコンサルタントとデータを共有する場合があります。また、弊社が収集する情報の一部は、弊社に代わって第三者たるプロバイダーが収集する場合があります。
        </li>
        <li>
          お客様の同意がある場合。これには、お客様がご自身の「Journey」アカウントを他社が提供するサービス（FacebookやTwitterなど）上のアカウントとリンクすることを選択した場合や、本サービス上でのお客様の活動を他社が提供するサービス上で公開することを選択した場合における、他社が提供するサービスとの情報共有が含まれます。
        </li>
        <li>法令に基づく場合。</li>
        <li>
          人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき。
        </li>
        <li>
          公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき。
        </li>
        <li>
          国の機関もしくは地方公共団体又はその委託を受けたものが法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。
        </li>
        <li>
          合併、買収、破産、解散、再編、または類似の取引や手続きによる事業の承継に伴い、本プライバシーポリシーに記載されている情報の移転をする場合。
        </li>
        <li>
          統計情報や個人を特定できない情報を、弊社のパートナー会社その他の企業と共有する場合。例えば、特定の動画その他のコンテンツを何名のユーザーがお気に入りリストに追加したか、動画その他のコンテンツを共有したか、動画その他のコンテンツを視聴した後に、ハッピー、リラックス、インスピレーション等の反応をしたユーザーの割合、および動画その他のコンテンツに対する関心度などの情報を、パートナー会社と共有する場合があります。
        </li>
      </ul>
      <h3>4. お客様の情報の国外移転に関する方針</h3>
      <p>
        お客様が本サービスをご利用になるに際し、本プライバシーポリシーに記載されている目的のために、お客様の同意を得て、弊社がお客様の情報をお客様の居住国以外に転送（日本と米国との間の転送を含む）し、保管することがあります。なお、上記の国におけるプライバシー保護や当局によるお客様の個人情報へのアクセス権は、お客様の国のものと異なる場合があります。
      </p>
      <h3>5. 個人情報に関するお客様の変更可能な項目に関する方針</h3>
      <p>
        弊社は、お客様がご自身の情報を容易かつ有意義に選択することができるように尽力しております。お客様が「Journey」アカウントをお持ちの場合、本サービス上のお客様の選択肢の多くは、サービスまたはお客様のアカウント設定に直接組み込まれております。例えば、以下の選択が可能です。{' '}
      </p>
      <ul>
        <li>
          お客様のプロフィールページの情報にいつでもアクセスし、変更することができます。また、プロフィールページを検索エンジンに公開するかどうかを選択し、お客様の電子メールアドレスを使って、他のユーザーがお客様の「Journey」アカウントを見つけることができるかどうかを選択することができます。
        </li>
        <li>
          「Journey」アカウントを他社が提供するサービス上のアカウント（SNSアカウントなど）とリンクしたり、リンクを解除したりすることができます。一部のサービス（Facebookなど）では、本サービスにおけるお客様の活動を、当該一部のサービスにおいて公開するかどうかについても選択することができます。
        </li>
        <li>
          「Journey」アカウントはいつでも閉鎖することができます。お客様が「Journey」アカウントを閉鎖した場合、弊社はお客様のアカウントを無効にし、本サービスから削除します。弊社は、法律で義務付けられている場合、または正当な事業目的（詐欺やスパムへの対応を含みます。）のために、お客様の情報のアーカイブを保持する場合があります。
          お客様が本サービスへのアクセスの際に使用するデバイスまたはソフトウェアによって、変更可能な項目が異なる場合があります。該当項目には以下のものが含まれます。
        </li>
        <li>
          モバイル端末では、位置情報その他のデータを弊社と共有する方法およびその可否について、お客様が選択できるようになっている場合があります。
          選択肢の詳細につきましては、端末またはソフトウェアの提供者が提供する情報をご参照ください。
        </li>
      </ul>
      <h3>6. 子供に関連する情報の取り扱いに関する方針</h3>
      <p>
        弊社のサービスは、13歳未満のお子様向けではありません。
        未成年の方が保護者の方の同意なく個人情報を提供したことが判明した場合は、弊社までご連絡ください。
      </p>
      <h3>7. 個人情報の管理、開示および削除の請求</h3>
      <p>
        本項は、個人情報保護法に基づき、保有個人データの取扱いに関して要求される開示事項を記載しています。
      </p>
      <ol>
        <li> 個人情報取扱事業者の名称： 株式会社Journey </li>
        <li>個人情報保護の責任者名： 株式会社Journey 個人情報保護管理者</li>
        <li>
          お客様による個人情報の開示、訂正、削除、利用停止の要求：
          弊社は、お客様が個人情報の開示、訂正、利用停止、削除をお求めになる場合、適用される法令および弊社の社内規程に基づいて速やかに対応します。お客様の権利の行使に関する詳細は、「お問い合わせ先」の項目に記載されている電子メールアドレス宛にお問い合わせください。
        </li>
      </ol>
      <h3>8. お問い合わせ先</h3>
      <p>
        本プライバシーポリシーについてご質問がございましたら、info@wellness-journey.io
        までお問い合わせください。
      </p>
    </div>
  )
}
