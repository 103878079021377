import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useScrollTracker } from 'react-scroll-tracker'

import { useConversion } from '../../hooks/useConversion'
import { useLatestRecordId } from '../../hooks/useLatestRecordId'
import { fetchRecord } from '../../stores/record'
import { makeResult } from '../../stores/result'
import AppError from '../app/AppError'
import LoginRandomGuide from '../app/LoginRandomGuide'
// import GenoplanBanner from '../app/GenoplanBanner'
import RadarChart from '../ui/RadarChart'
import Acceptability from './Acceptability'
import AccountGuide from './AccountGuide'
import StartAnalytics from './AnalyticsLink'
import ArticleSuggestDoctor from './ArticleSuggestDoctor'
import ArticleSuggestList from './ArticleSuggestList'
import LevelMeter from './LevelMeter'
import PrecisionPanel from './PrecisionPanel'
import RabbitType from './RabbitType'
import RankingChart from './RankingChart'
import ShareButtons from './ShareButtons'
import StressTimeline from './StressTimeline'

import styles, { block, cardBlock, whiteBlock } from './Result.module.scss'

export function Result({ recordId }) {
  useConversion()
  const { auth } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const {
    histgramData,
    precision,
    rabbitType,
    contentsTop5,
    rankTop5,
    rankWorst5,
    stressLevel,
    stressLevelRank,
    typeScore
  } = useSelector(state => state.result)
  const { fetchFailed } = useSelector(state => state.record)
  const latestRecordId = useLatestRecordId()

  // GA Scroll tracking
  const location = useLocation()
  useScrollTracker([25, 50, 75, 90], ({ scrollY }) => {
    window.gtag('event', `scroll_${scrollY}`, {
      event_category: 'scroll',
      event_label: location.pathname
    })
  })

  const isMyResult = latestRecordId === recordId
  useEffect(() => {
    dispatch(fetchRecord(recordId)).then(action => {
      dispatch(makeResult(action.payload))
    })
  }, [dispatch, recordId])
  if (fetchFailed) return <AppError />

  return (
    <div className={styles.container}>
      <Helmet>
        <title>診断結果</title>
      </Helmet>
      <div className={block}>
        <h2>診断結果</h2>
        <RabbitType rabbitType={rabbitType} precision={precision} />
        <ArticleSuggestDoctor articles={contentsTop5} />
      </div>
      <div className={ClassNames(cardBlock, { [styles.hide]: !isMyResult })}>
        <h3>ウサタイプを友達にシェアする</h3>
        <ShareButtons recordId={recordId} />
      </div>
      <div className={ClassNames(block, { [styles.hide]: isMyResult })}>
        <StartAnalytics />
      </div>
      <div className={ClassNames(block, { [styles.hide]: !isMyResult })}>
        <AccountGuide />
      </div>
      <div className={block}>
        <h2>あなたの傾向</h2>
        <RadarChart data={typeScore} />
      </div>
      <div className={block}>
        <h2>今のストレスレベル</h2>
        <LevelMeter
          value={stressLevel}
          rank={stressLevelRank}
          histgramData={histgramData}
        />
      </div>
      <div className={ClassNames(whiteBlock, { [styles.hide]: !isMyResult })}>
        <h2>ストレスレベルの推移</h2>
        <div className={styles.caption}>
          {`定期的に診断することで、\nあなたのストレスの推移がわかります`}
        </div>
        <StressTimeline />
        <div className={styles.caution}>
          {`※ このグラフは友達にはシェアされません`}
        </div>
      </div>

      <div className={block}>
        <h2>{`あなたがやると\nいいことランキング`} </h2>
        <div className={styles.caption}>
          {`あなたにとってストレスが解消されるものを\n77個の中から5つ表示しています。`}
        </div>
        <RankingChart entries={rankTop5} color='primary' />
      </div>
      {isMyResult && (
        <div className={block}>
          <h2>{'あなたがやらないほうが\nいいことランキング'}</h2>
          <div className={styles.caption}>
            {`あなたにとってストレスを溜めてしまうものを\n77個の中から5つ表示しています。`}
          </div>
          <RankingChart
            entries={rankWorst5}
            color='secondary'
            masked={auth.uid == null}
          />
        </div>
      )}
      <div className={block}>
        <h2>{`あなたに合った解消法`}</h2>
        <ArticleSuggestList articles={contentsTop5} />
      </div>
      <div className={ClassNames(block, { [styles.hide]: !isMyResult })}>
        <Acceptability recordId={recordId} />
      </div>
      <div className={ClassNames(cardBlock, { [styles.hide]: !isMyResult })}>
        <h2>診断精度</h2>
        <PrecisionPanel precision={precision} />
      </div>
      <div className={ClassNames(cardBlock, { [styles.hide]: !isMyResult })}>
        <h3>ウサタイプを友達にシェアする</h3>
        <ShareButtons recordId={recordId} />
      </div>
      <div className={ClassNames(block, { [styles.hide]: isMyResult })}>
        <StartAnalytics />
      </div>
      <LoginRandomGuide />
      {/* <div className={ClassNames(cardBlock, { [styles.hide]: !isMyResult })}>
        <h3>さらに詳細に知りたい人は</h3>
        <div className={styles.caption}>
          {`遺伝子を調べると、生まれながらに\n持つ精神回復力などがわかります`}
        </div>
        <GenoplanBanner />
      </div> */}
    </div>
  )
}
export default Result

Result.propTypes = {
  recordId: PropTypes.string
}
