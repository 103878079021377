import classNames from 'classnames'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Helmet } from 'react-helmet-async'

import { getTypeData } from '../../logic'
import { loadQuestion } from '../../stores/question'
import { RabbitTypeImage } from '../result/RabbitTypeImage'

import styles from './RabbitTypeList.module.scss'

const { logicVersion } = loadQuestion()
const typeData = getTypeData({ logicVersion })

export default function RabbitTypeList() {
  const [isTop, setIsTop] = useState(true)

  const onScroll = () => {
    setIsTop(window.pageYOffset < 500)
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  })

  return (
    <div className={styles.container}>
      <Helmet>
        <title>うさタイプ一覧</title>
      </Helmet>
      <motion.div
        animate={{ opacity: 1.0 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h3 id='top'>うさタイプ一覧</h3>
        <div className={styles.typeList}>
          {typeData.data.map(t => (
            <AnchorLink href={`#type${t.typeId}`} key={`tl${t.name}`}>
              {t.name.match(/「([^)]+)」/)[1]}
            </AnchorLink>
          ))}
        </div>
        {typeData.data.map(t => (
          <div
            className={styles.typeBlock}
            id={`type${t.typeId}`}
            key={`tb${t.name}`}
          >
            <div className={styles.typeImage}>
              <RabbitTypeImage typeId={t.typeId} />
            </div>
            <div className={styles.typeNumber}>TYPE {t.typeId}</div>
            <div className={styles.typeName}>{t.name}</div>
            <div className={styles.typeText}>{t.text}</div>
          </div>
        ))}
        <AnchorLink href='#top'>
          <div
            className={classNames(styles.backLink, { [styles.hide]: isTop })}
          >
            ↑
          </div>
        </AnchorLink>
      </motion.div>
    </div>
  )
}
