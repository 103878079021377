import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchSctipt = createAsyncThunk(
  'instruction/fetchSctipt',
  async (args, thunkAPI) => {
    const response = await fetch(`/scripts/instruction/${args}.json`)
    const data = await response.json()
    return { script: data }
  }
)

const resetScript = state => {
  const { script } = state
  if (script.pages == null || script.pages.length === 0) return
  state.currentPage = script.pages[0]
  state.isFinish = false
}

export const instruction = createSlice({
  name: 'instruction',
  initialState: {
    script: {},
    currentPage: null,
    isFinish: false
  },
  reducers: {
    next: state => {
      const { script, currentPage } = state
      if (script.pages == null || currentPage == null) return
      const idx = script.pages.findIndex(el => el.id === currentPage.id)
      const nextIdx = idx + 1
      if (script.pages.length <= nextIdx) return
      state.currentPage = script.pages[nextIdx]
    },
    exit: state => {
      state.isFinish = true
    },
    jump: (state, action) => {
      const nextId = action.payload
      const { script } = state
      if (script.pages == null) return
      const nextIdx = script.pages.findIndex(el => el.id === nextId)
      if (nextIdx === -1) return
      state.currentPage = script.pages[nextIdx]
    },
    reset: state => {
      resetScript(state)
    }
  },
  extraReducers: {
    [fetchSctipt.fulfilled]: (state, action) => {
      const { script } = action.payload
      state.script = script
      resetScript(state)
    }
  }
})
export const { next, exit, jump, reset } = instruction.actions

export default instruction.reducer
