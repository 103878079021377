import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const APP_INITIAL_STATE = {
  disableFooter: false
}

export const app = createSlice({
  name: 'app',
  initialState: _.cloneDeep(APP_INITIAL_STATE),
  reducers: {
    setDisableFooter: (state, action) => {
      state.disableFooter = action.payload
    }
  }
})

export const { setDisableFooter } = app.actions

export default app.reducer
