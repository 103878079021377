import React from 'react'

import styles from './Contact.module.scss'

export function Contact() {
  return (
    <div className={styles.container}>
      <h3>ご意見・ご要望</h3>
      <p>
        現在ご利用いただいているUSABARA診断は本リリース前のアルファ版（テスト版）です。
        今後改善していく上でのご意見・ご要望などございましたらアンケートにご記入ください。
      </p>
      <a
        href='https://docs.google.com/forms/d/e/1FAIpQLSd_LiSdsIo3TApGgSeAyjLAeyGmVDb9F2sRfVYR1UefkIcFDA/viewform'
        target='_blank'
        rel='noreferrer'
      >
        アンケート
      </a>
      <br />
      <h3>その他のお問い合わせ</h3>
      <p>
        お問い合わせにつきましては、対応できない場合もございますので、予めご了承ください。
      </p>
      <a href='mailto:support@usabara.com'>support@usabara.com</a>
      <br />
      <div className={styles.poweredby}>運営元会社 株式会社Journey</div>
    </div>
  )
}
export default Contact
