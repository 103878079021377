import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addAnsweredItemIds, changeValue } from '../../stores/analysis'
import { AnswerSelect } from './AnswerSelect'
import { AnswerSlider } from './AnswerSlider'

export const AnswerForm = ({ currentQuestion }) => {
  const dispatch = useDispatch()
  const { questionType } = currentQuestion
  const { currentInput } = useSelector(state => state.analysis)

  useEffect(() => {
    if (currentQuestion.items == null) return
    dispatch(addAnsweredItemIds(currentQuestion.items.map(i => i.itemId)))
  }, [currentQuestion, dispatch])

  const updateValue = value => {
    dispatch(changeValue(value))
  }

  if (questionType === 'slider') {
    return (
      <AnswerSlider
        question={currentQuestion}
        record={currentInput}
        onChange={v => updateValue(v)}
      />
    )
  } else if (
    questionType === 'multi_select' ||
    questionType === 'single_select'
  ) {
    return (
      <AnswerSelect
        question={currentQuestion}
        record={currentInput}
        onChange={v => updateValue(v)}
      />
    )
  } else {
    return <></>
  }
}
AnswerForm.propTypes = {
  currentQuestion: PropTypes.object
}
