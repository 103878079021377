import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import { sendGaEvent } from '../../GaEvent'
import Button from '../ui/Button'

import styles from './LoginGuide.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    border: 'none'
  }
}))
export const LoginGuide = ({ message = '' }) => {
  const classes = useStyles()
  const { auth } = useSelector(state => state.user)
  if (auth.uid != null) return <></>
  return (
    <div className={styles.container}>
      <div
        className={ClassNames(styles.doctor, {
          [styles.hidden]: message.length === 0
        })}
      />
      <div className={styles.message}>{message}</div>
      <div className={styles.buttons}>
        <Button
          text='アカウント作成（無料）'
          href={`/signup`}
          color='primary'
          fullWidth
          onClick={() => sendGaEvent('guide_to_signup', 'login', message)}
        />
        <Button
          text='ログイン'
          href={`/login`}
          color='secondary'
          classes={classes}
          fullWidth
          onClick={() => sendGaEvent('guide_to_login', 'login', message)}
        />
      </div>
    </div>
  )
}

LoginGuide.propTypes = {
  message: PropTypes.string
}

export default LoginGuide
