import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { sendGaEvent } from '../../GaEvent'
import { AnnounceDoctor } from '../ui/AnnounceDoctor'
import { Button } from '../ui/Button'
import AlphaNotice from './AlphaNotice'

import styles from './Home.module.scss'
const useButtonStyles = makeStyles(theme => ({
  root: { width: 240 }
}))

export function HomeBeforeAnalysis() {
  return (
    <div className={styles.container}>
      <AnnounceDoctor
        textStyle={{ fontWeight: 'normal' }}
        text={`ようこそ。わしゃ<b>うさばら博士</b>じゃ\nわしが長年の研究で生み出した<b>うさばら診断</b>で、君にピッタリのうさの晴らし方を見つけますぞ`}
      />
      <div className={styles.block}>
        <Button
          text='診断を開始する'
          href='/analysis/'
          color='primary'
          onClick={() =>
            sendGaEvent('go_to_analysis', 'analysis', '新規訪問画面')
          }
          classes={useButtonStyles()}
        />
      </div>
      <div className={styles.block}>
        <AlphaNotice />
      </div>
      <div className={styles.block}>
        <h3>うさばら診断とは？</h3>
        <p>
          あなたの状態を医学統計データをもとに分析し、あなたに合った解消法を紹介します。周りの人に言いにくく、つい溜め込みがちだったストレスについて、もっと手軽に知って発散でき、気軽に話せる世の中になってほしい。ちょっと大げさかもしれませんが、そんな未来を目指したサービスです。
        </p>
      </div>
    </div>
  )
}
export default HomeBeforeAnalysis
