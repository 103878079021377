import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  clearExceptonItemIds,
  forceFinish,
  init,
  next,
  prev
} from '../../stores/analysis'
import { setDisableFooter } from '../../stores/app'
import {
  saveState,
  setIsFirstLaunch,
  setLatestRecordId
} from '../../stores/localStore'
import { addRecord, fetchRecord, makeRecordData } from '../../stores/record'
import { AnswerContainer } from './AnswerContainer'
import { AnswerForm } from './AnswerForm'
import { PrecisionBar } from './PrecisionBar'

import styles from './Analysis.module.scss'

export function Analysis({ recordId = null }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    currentIndex,
    currentInput,
    currentAnsweredItemIds,
    currentPrecision,
    isFinish,
    script
  } = useSelector(state => state.analysis)
  const [currentQuestion, setCurrentQuestion] = useState({})

  useEffect(() => {
    const initAnalytics = async () => {
      if (recordId == null) {
        await dispatch(clearExceptonItemIds())
        await dispatch(saveState())
        dispatch(init())
      } else {
        const record = await dispatch(fetchRecord(recordId))
        dispatch(init(record.payload))
      }
    }
    if (!isFinish) {
      initAnalytics()
    }
    dispatch(setDisableFooter(true))
    return () => {
      dispatch(setDisableFooter(false))
    }
  }, [dispatch, recordId, isFinish])

  useEffect(() => {
    const saveRecord = async () => {
      const record = makeRecordData(
        script,
        currentInput,
        currentAnsweredItemIds
      )
      const saveId = (await dispatch(addRecord(record))).payload
      await dispatch(setLatestRecordId(saveId))
      await dispatch(setIsFirstLaunch(false))
      await dispatch(saveState())
      // console.log(record)
      history.push('/result')
    }
    if (isFinish) {
      saveRecord()
    }
  }, [
    dispatch,
    history,
    isFinish,
    currentInput,
    script,
    currentAnsweredItemIds
  ])

  useEffect(() => {
    if (script.data == null) return
    setCurrentQuestion(script.data[currentIndex])
  }, [script.data, currentIndex])

  const onClickFinish = () => {
    dispatch(forceFinish())
  }
  const onClickBack = () => {
    dispatch(prev())
    if (currentIndex === 0) {
      history.goBack()
    }
  }

  return (
    <div
      className={styles.container}
      // For fitting iOS and Android widhout navigation
      style={{ height: window.innerHeight - 64 }}
    >
      <Helmet>
        <title>{`質問${currentIndex + 1}`}</title>
      </Helmet>
      <motion.div
        animate={{ opacity: 1.0 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <AnswerContainer
          onClickNext={e => dispatch(next())}
          onClickBack={e => onClickBack()}
          questionText={currentQuestion.questionText}
        >
          <AnswerForm currentQuestion={currentQuestion} />
        </AnswerContainer>
        <PrecisionBar
          precision={currentPrecision}
          onClickFinish={() => onClickFinish()}
        />
      </motion.div>
    </div>
  )
}
export default Analysis

Analysis.propTypes = {
  recordId: PropTypes.string
}
