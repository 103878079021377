import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from './Button'
import { UsagiIcon } from './ButtonIcons'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#EB5959'
  }
}))

export const ButtonUsagi = props => {
  const classes = useStyles()
  return (
    <Button
      {...props}
      classes={classes}
      color='primary'
      startIcon={<UsagiIcon />}
      fullWidth
    />
  )
}

export default ButtonUsagi
