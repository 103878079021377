import baseScript from './question0825.json'

export const loadQuestion = () => {
  const data = []
  const { logicVersion, scriptVersion, questions } = baseScript
  questions.forEach(q => {
    const { questionId, questionText, questionType, minLabel, maxLabel } = q
    const base = { questionId, questionText, questionType }
    switch (questionType) {
      case 'slider':
        data.push(
          ...q.items.map(i => ({
            ...base,
            items: [
              {
                flipAnimation: i.flipAnimation,
                itemId: i.itemId,
                sliderImage: i.sliderImage,
                text: i.text,
                minLabel,
                maxLabel
              }
            ]
          }))
        )
        break
      case 'single_select':
      case 'multi_select':
        data.push({ ...base, items: q.items })
        break
      default:
        break
    }
  })
  return { logicVersion, scriptVersion, data }
}
