import PropTypes from 'prop-types'
import React from 'react'

import { sendGaEvent } from '../../GaEvent'
import Button from '../ui/Button'
import PrecisionScale from '../ui/PrecisionScale'

import styles from './PrecisionPanel.module.scss'
export const PrecisionPanel = ({ precision }) => {
  return (
    <div className={styles.container}>
      <PrecisionScale precision={precision} />
      <div className={styles.description}>
        質問にもっと答えるとあなたの
        <br />
        タイプをより正確に診断できます
      </div>
      <div className={styles.buttons}>
        <div className={styles.button}>
          <Button
            text='追加回答で精度をあげる'
            href={`/analysis`}
            color='primary'
            fullWidth
            onClick={() =>
              sendGaEvent('go_to_analysis', 'analysis', '結果画面：追加回答')
            }
          />
        </div>
        <div className={styles.button}>
          <Button
            text='診断をやりなおす'
            href='/analysis/new'
            color='secondary'
            fullWidth
            onClick={() =>
              sendGaEvent('go_to_analysis', 'analysis', '結果画面：新規診断')
            }
          />
        </div>
      </div>
    </div>
  )
}

PrecisionPanel.propTypes = {
  precision: PropTypes.number
}

export default PrecisionPanel
