// Takram Confidential
// Copyright (C) 2019-Present Takram

/* eslint-disable */
import firebase from 'firebase/app'
import firestore from 'firebase/firestore'

const settings = {}

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,  
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
}
firebase.initializeApp(config)

firebase.firestore().settings(settings)

export default firebase
