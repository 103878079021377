import React from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './TermsOfService.module.scss'

export default function TermsOfService() {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>利用規約</title>
      </Helmet>
      <h1>利用規約</h1>
      <p>
        株式会社JOURNEY（以下「当社」といいます）が提供するサービス「USABARA」（以下「本サービス」といいます）の利用については、本規約に必ず同意のうえご利用ください。
      </p>
      <br />
      <h3>第1条（適用範囲）</h3>
      <ol>
        <li>
          USABARA利用規約（以下「本規約」といいます）は、利用するすべての方（以下「利用者」といいます）と当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
        </li>
        <li>
          当社は本サービスに関し、本規約のほか、各種の規定（以下「個別規定」といいます）を設けることがあります。これら個別規定はその名称のいかんに拘わらず、本規約の一部を構成するものとします。
        </li>
        <li>
          本規約の規定が前項の個別規定の内容と異なる場合には、個別規定において特段の定めのない限り、個別規定の内容が優先されるものとします。
        </li>
        <li>
          本規約及び個別規定（以下、併せて「本規約等」といいます）については、法令に別段の定めがある場合を除き、利用者に対する事前の通知なく、当社の判断により、いつでも任意に変更ができるものとします。本規約等が変更された場合、当社が別途定める場合を除き、当社アプリ上に表示した時点により効力を生じるものとします。また、当該変更後の利用者による本サービスの利用には変更後の本規約等が適用されるものとし、当該利用により、利用者は当該変更に同意したものとみなされます。
        </li>
      </ol>
      <br />
      <h3>第2条（定義）</h3>
      <p>
        本規約において使用する用語の意義は、次の各号の定めるとおりとします。
      </p>
      <ol>
        <li>
          「本サービス」とは、利用者が当社サイト又は当社アプリ上で提供するストレス対処法推奨システムに関するプログラム及びこれに付随して提供する全てのサービス（記事等の推奨サービスを含みます。）をいいます。
        </li>
        <li>
          「当社アプリ」とは、スマートフォン向けアプリケーション「USABARA」のことをいいます（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のアプリケーションを含みます）。
        </li>
        <li>
          「コンテンツ」とは、データ、文書、ソフトウェア、画像、文字、音声等その他一切の情報をいいます。
        </li>
      </ol>
      <h3>第3条（使用許諾）</h3>
      <ol>
        <li>
          当社は、利用者が本規約を遵守することを条件として、利用者に対して、当社アプリを利用者の端末においてのみ使用することのできる、非独占的かつ譲渡及びサブライセンス不能な当社アプリの使用権を許諾します。当社アプリは、利用者が個人で使用する目的でのみ利用することができます。
        </li>
        <li>
          本サービスの利用を希望する者は、自らの意思及び責任をもって、本規約の内容に同意した上で、本サービスを利用するものとします。
        </li>
      </ol>
      <h3>第4条（禁止事項）</h3>
      <p>
        利用者は、本サービスの利用に際して、以下の各号に掲げる事項に該当する、もしくはそのおそれのある行為を行ってはなりません。
      </p>
      <ol>
        <li>本規約等に違反する行為</li>
        <li>法令又は公序良俗に違反する行為</li>
        <li>犯罪行為に関連する行為</li>
        <li>
          当社、利用者又は第三者の著作権、商標権ほか知的財産権を侵害する行為
        </li>
        <li>
          当社、利用者又は第三者の財産・信用・名誉・プライバシーを侵害する行為
        </li>
        <li>
          本サービスによって得られた情報を商業的に利用する行為（当社が許諾したものを除く）
        </li>
        <li>
          本サービスの全部又は一部について、有償、無償を問わず第三者に販売、頒布、送信可能化、公衆送信、貸与、譲渡、使用許諾、その他の処分をする行為
        </li>
        <li>本サービスのネットワーク又はシステム等に過度な負荷をかける行為</li>
        <li>本サービスのネットワーク又はシステム等に不正にアクセスする行為</li>
        <li>
          本サービスに係る技術情報の複製、改変、翻案、逆コンパイル、逆アセンブル、リバースエンジニアリング、追加、加工又は変更を行う行為
        </li>
        <li>他の利用者に関する個人情報等を収集又は蓄積する行為</li>
        <li>他の利用者に対する嫌がらせや誹謗中傷を目的とする行為</li>
        <li>不特定多数の利用者に発信する行為等、当社がスパムと判断する行為</li>
        <li>
          当社のサービスに関連して、反社会的勢力に対して直接又は間接に利益を供与する行為
        </li>
        <li>その他、当社が不適切と判断する行為</li>
      </ol>
      <h3>第5条（サービス提供の停止）</h3>
      <ol>
        <li>
          当社は、以下のいずれかの事由があると判断した場合、利用者に事前に通知することなく本サービスの全部又は一部の提供を停止又は中断することができるものとします。
          <ol>
            <li>
              本サービスに係るシステムやインフラストラクチャの点検又は保守作業を行う場合
            </li>
            <li>
              停電、天災地変などの不可抗力により本サービスの提供ができなくなった場合
            </li>
            <li>
              コンピューター、通信回線等が事故により本サービスの提供ができなくなった場合
            </li>
            <li>
              本サービスの提供に必要なプラットフォーム、クラウドコンピューティングサービス、第三者が提供する関連サービスに不具合が発生した場合
            </li>
            <li>その他、当社が停止又は中断を必要と判断した場合</li>
          </ol>
        </li>
        <li>
          当社は、本サービスの提供の停止又は中断により、利用者又は第三者が被ったいかなる不利益又は損害について、一切の責任を負わないものとします。
        </li>
      </ol>
      <h3>第6条（本サービスの変更、終了）</h3>
      <ol>
        <li>
          当社は本サービスの全部又は一部を、利用者に対して事前に通知することなく随時変更することができるものとします。
        </li>
        <li>
          当社は利用者に対して本サービスの全部又は一部の提供を事前に告知の上、永続的に終了、又は一時的に中断することができるものとします。ただし、やむを得ない場合又は緊急の場合には利用者に対してこれを事前に告知せず、終了又は中断することができるものとします。
        </li>
        <li>
          当社は、本サービスの変更等により利用者に生じたいかなる損害等についても、一切責任を負うものではありません。
        </li>
      </ol>
      <h3>第7条（保証の否認及び免責事項）</h3>
      <ol>
        <li>
          利用者は、自己の責任において当社アプリを利用者自身のスマートフォン等の携帯端末にダウンロードし、インストールするものとします。なお、当社アプリが全ての携帯端末に対応することを保証するものではありません。
        </li>
        <li>
          利用者は、自己の責任において本サービスを利用するものとし、(1)
          利用者が本サービスを利用したこと、又は利用できなかったこと、(2)
          不正アクセスや不正な改変がなされたこと、(3)
          本サービス中の他の利用者による行為、(4) 第三者のなりすまし行為、(5)
          その他本サービスに関連する事項に起因又は関連して生じた一切の損害（精神的苦痛又は逸失利益その他の金銭的損失を含む一切の不利益）に関して、当社が損害賠償責任を負わないことに同意します。
        </li>
        <li>
          当社は、本サービスに事実上又は法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、第三者の権利侵害などを含みます）がないことを保証しません。当社は、万一本サービスに瑕疵があることが判明した場合、その修正に努めますが、本サービスの瑕疵に起因して利用者に発生した損害について、一切責任を負わないものとします。
        </li>
        <li>
          {' '}
          当社は、本サービス（本サービスにおいて提供される診断・分析結果、本サービスにおいて推奨される記事等のコンテンツを含みます。）が、何らかの疾病の治療又は予防、健康状態や生活習慣の改善に効果があることを保証しません。
        </li>
        <li>
          当社は、利用者が本サービスを利用することにより取得した情報、コンテンツ、データ、及びその他の情報に関する問い合せについては、対応する義務を負わないものとします。
        </li>
        <li>
          {' '}
          当社は、利用者が本サービスを利用することにより取得した情報、コンテンツ、データ、及びその他の情報の完全性、正確性、確実性、有用性等について、何らの保証も行わず、責任を負担しないものとします。
        </li>
        <li>
          当社は、本サービスに関して、利用者間又は利用者と第三者との間において生じた取引、連絡又は紛争等について一切責任を負いません。万一、利用者間又は利用者と第三者間に紛争等が発生し、当社が直接これにやむを得ず対応した場合、利用者は、当社に発生した損害、費用（合理的な弁護士費用を含みます）等の一切を補償するものとします。
        </li>
        <li>
          本サービスの利用者の端末に保存された情報の一切の管理責任は利用者にあるものとし、当社は、端末の紛失、盗難、ハッキングその他の事象により引き起こされた損害については、一切の責任を負わないものとします。
        </li>
        <li>
          当社は、本サービスに関連して当社のサーバー等に保存されたデータ等の保存義務を負わないものとし、当該データ等の滅失、損傷、漏洩については、データ等の復旧、損害の賠償その他一切の責任を負わないものとします。
        </li>
        <li>
          本規約が消費者契約法第2条3項の消費者契約に該当する場合には、本規約のうち、当社の債務不履行又は不法行為に基づく損害賠償責任を完全に免責する規定は適用されないものとします。この場合において、当社は、当該利用者が直接被った損害を限度として損害賠償責任を負うものとします。ただし、当社に故意又は重大な過失がある場合を除きます。
        </li>
      </ol>
      <h3>第8条（個人情報の取扱い）</h3>
      <p>
        当社は、本サービスの利用によって取得する個人情報について、当社「プライバシーポリシー」に従い適切に取り扱うものとします。
      </p>
      <ol>
        <li>
          利用者は、本サービスを利用するにあたり、必要なスマートデバイス、通信機器、オペレーションシステム、通信手段及び電力などを、利用者の費用と責任で用意しなければなりません。なお、利用者が未成年者である場合は、親権者など法定代理人が利用者に使用を認めたものをご使用ください。
        </li>
        <li>
          利用者は自己の利用環境に応じて、コンピュータウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を講じるものとします。
        </li>
        <li>
          当社は利用者の利用環境について一切関与せず、また一切の責任を負いません。
        </li>
      </ol>
      <h3>第10条（統計データ等の閲覧、利用）</h3>
      <p>
        当社は、利用者の利用履歴、その他コンテンツ等を、個人を識別、特定できないように加工、集計、及び分析した統計データ等を作成し、当該利用履歴又は統計データ等を何らの制限なく閲覧、利用（第三者に閲覧、利用させること、マーケティング資料としての提供、本サービスの新機能の開発、市場の調査を含みますが、これに限られません）することができるものとし、利用者はこれを予め承諾するものとします。
      </p>
      <h3>第11条(本サービスの権利帰属) </h3>
      <ol>
        <li>
          本サービスに含まれているコンテンツ及び個々の情報、商標、画像、広告、デザイン等に関する著作権、商標権その他の財産権は、当社もしくは当該コンテンツ等を創作した著作者又は著作権者に帰属します。
        </li>
        <li>
          本サービス及び関連して使用されている全てのソフトウェアは、知的財産権に関する法令等により保護されている財産権を含みます。利用者は、当社及び著作権その他の財産権を有する第三者から利用・使用を許諾されている場合、及び、法令により権利者からの許諾なく利用又は使用が許容されている場合を除き、本サービス及び関連して使用されるすべてのソフトウェアの内容を複製、編集、掲載、公衆送信、配布、販売、提供、翻訳その他あらゆる利用又は使用を行ってはなりません。
        </li>
        <li>
          利用者が前項に違反する行為によって被った損害については、当社は一切の責任を負わないものとします。また、利用者がこれらの行為によって利益を得た場合、当社はその利益相当額を請求できる権利を有するものとします。
        </li>
      </ol>
      <h3>第12条（反社会的勢力等の排除）</h3>
      <p>
        当社は、反社会的勢力等による本サービスの利用を禁止します。当社は、利用者が反社会的勢力等に該当すると判断した場合、事前に利用者に通知することなく、本サービスの提供を停止することができます。当社は、当該提供停止によって利用者に生じた損害や不利益について、一切の責任を負いません。
      </p>
      <h3>第13条（権利義務の譲渡の禁止）</h3>
      <ol>
        <li>
          利用者は、当社の書面による事前の承諾なく、本規約に基づく権利もしくは義務を第三者に譲渡、又は担保に供することはできません。
        </li>
        <li>
          当社は、本サービスに関する事業を合併、事業譲渡その他の事由により第三者に承継させる場合には、当該事業の承継に伴い、本規約等上の地位、本規約等に基づく権利、義務及び利用者の情報、その他のコンテンツを当該事業の承継人に譲渡することができるものとし、利用者は、かかる譲渡について本項において予め同意したものとします。
        </li>
      </ol>
      <h3>第14条（分離可能性）</h3>
      <ol>
        <li>
          本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規約は有効であるものとします。
        </li>
        <li>
          本規約のいずれかの条項又はその一部が、特定の利用者との関係で無効、又は取り消された場合であっても、本規約はその他の利用者との関係では有効とします。
        </li>
      </ol>
      <h3>第15条（準拠法と裁判管轄）</h3>
      <ol>
        <li>本規約の解釈にあたっては日本法を準拠法とします。</li>
        <li>
          本サービスに関して紛争が生じた場合には、東京地方裁判所を専属的合意管轄とします。
        </li>
      </ol>
      <p>附則 2021年9月1日制定</p>
    </div>
  )
}
