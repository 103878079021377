import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      // 'box-sizing': 'border-box',
      margin: '8px 0'
      // margin: theme.spacing(1)
      // width: '25ch'
    },
    '& label.Mui-focused': {
      color: '#0D76D6'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0D76D6'
    }
  }
}))

export const TextInput = props => {
  const classes = useStyles()
  return (
    <form className={classes.root} noValidate autoComplete='off'>
      <TextField {...props} />
    </form>
  )
}

TextInput.defaultProps = {
  // color: 'default',
  fullWidth: false,
  size: 'medium',
  value: '',
  label: '',
  type: 'string',
  // variant: 'contained',
  onChange: () => {}
}

TextInput.propTypes = {
  // color: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  // variant: PropTypes.string,
  onChange: PropTypes.func
}

export default TextInput
