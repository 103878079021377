import PropTypes from 'prop-types'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'

import { sendGaEvent } from '../../GaEvent'

import FacebookIcon from '../../assets/images/icon/share_facebook@2x.png'
import LineIcon from '../../assets/images/icon/share_line@2x.png'
import LinkIcon from '../../assets/images/icon/share_link@2x.png'
import TwitterIcon from '../../assets/images/icon/share_twitter@2x.png'
import styles from './ShareButtons.module.scss'

const typeImageNames = [
  ['t011', 't012', 't013'],
  ['t021', 't022', 't023'],
  ['t031', 't032', 't033'],
  ['t041', 't042', 't043'],
  ['t051', 't052', 't053'],
  ['t061', 't062', 't063'],
  ['t071', 't072', 't073'],
  ['t081', 't082', 't083'],
  ['t091', 't092', 't093'],
  ['t101', 't102', 't103'],
  ['t111', 't112', 't113'],
  ['t121', 't122', 't123']
]

const getGaUtm = media =>
  `utm_source=${media}&utm_medium=referral&utm_campaign=alpha`

const sendGa = (sns, type, level) =>
  sendGaEvent(`share_to_${sns}`, 'result', `RabbitType ${type}`, level)

export const ShareButtons = ({ recordId }) => {
  const { rabbitType, precision } = useSelector(state => state.result)
  let { typeId } = rabbitType
  typeId = typeId || 1
  const level = precision < 0.8 ? 0 : precision < 0.85 ? 1 : 2
  const typeImageName = typeImageNames[typeId - 1][level]
  const shortName =
    rabbitType.name == null ? '' : rabbitType.name.match(/「([^)]+)」/)[1]
  const baseUrl = process.env.REACT_APP_BASE_URL
  const shareUrl = `${baseUrl}/share/${typeImageName}${recordId}`
  const resultUrl = `${baseUrl}/result/${recordId}`
  const text = `あなたのうさぎは 【${rabbitType.name}】です%0a%0a${rabbitType.text}%0a`
  const lineText = `${text}%0a${resultUrl}?${getGaUtm('line')}`
  const twHashTag = `USABARA,${shortName}`

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <div className={styles.button}>
          <a
            className='twitter-share-button'
            data-show-count='false'
            href={`https://twitter.com/share?url=${shareUrl}&text=${text}&hashtags=${twHashTag}`}
            onClick={() => sendGa('twitter', typeId, level)}
            target='_blank'
            rel='nofollow noopener noreferrer'
          >
            <img src={TwitterIcon} alt='twitterIcon' />
          </a>
          <script
            async
            src='https://platform.twitter.com/widgets.js'
            charSet='utf-8'
          ></script>
        </div>

        <div className={styles.button}>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${text}`}
            rel='nofollow noopener noreferrer'
            target='_blank'
            onClick={() => sendGa('facebook', typeId, level)}
          >
            <img src={FacebookIcon} alt='facebookIcon' />
          </a>
        </div>

        <div className={styles.button}>
          <a
            href={`https://line.me/R/msg/text/?${lineText}`}
            onClick={() => sendGa('line', typeId, level)}
            target='_blank'
            rel='nofollow noopener noreferrer'
          >
            <img src={LineIcon} alt='lineIcon' />
          </a>
        </div>

        <div
          className={styles.button}
          onClick={() => sendGa('link', typeId, level)}
        >
          <CopyToClipboard
            text={resultUrl}
            onCopy={() =>
              alert(`クリップボードに結果へのURLをコピーしました！`)
            }
          >
            <img src={LinkIcon} alt='linkIcon' />
          </CopyToClipboard>
        </div>
      </div>
    </div>
  )
}

ShareButtons.propTypes = {
  recordId: PropTypes.string
}

export default ShareButtons
