import * as d3 from 'd3'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import styles from './RadarChart.module.scss'

export function RadarChart({ data = [] }) {
  const graphRef = useRef()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [svg, setSvg] = useState(null)

  useEffect(() => {
    const node = graphRef.current
    const w = node.getBoundingClientRect().width
    const h = node.getBoundingClientRect().height
    const svg = d3.select(node).attr('height', h).attr('width', w)
    svg.append('g').attr('class', 'data-path')
    svg.append('g').attr('class', 'grid-path')
    svg.append('g').attr('class', 'axis')
    setWidth(w)
    setHeight(h)
    setSvg(svg)
  }, [])

  useEffect(() => {
    if (svg == null || data.length === 0 || data[0].length === 0) return
    const mv = Math.max(
      ...data.map(d => Math.max(...d.map(v => Math.abs(v.value))))
    )
    const maxValue = isFinite(mv) || mv < 2.0 ? 2.0 : mv
    const grid = [new Array(6).fill(0), new Array(6).fill(maxValue)]
    const r = (width / 2) * 0.5
    const radians = Math.PI * 2
    const scale = d3.scaleLinear().domain([-maxValue, maxValue]).range([0, r])
    const line = d3
      .line()
      .x((d, i) => scale(d) * Math.sin((radians / 6) * i) + width / 2)
      .y((d, i) => -scale(d) * Math.cos((radians / 6) * i) + height / 2)
    const dataPath = svg.select('.data-path').selectAll('path').data(data)
    dataPath.exit().remove()
    dataPath
      .enter()
      .append('path')
      .attr('d', (d, i) => line(d.map(el => el.value)) + 'z')
      .attr('fill', '#90D7FF')
      .attr('fill-opacity', 0.5)
      .attr('stroke-width', 2)
    dataPath.attr('d', (d, i) => line(d.map(el => el.value)) + 'z')

    const gridPath = svg.select('.grid-path').selectAll('path').data(grid)
    gridPath.exit().remove()
    gridPath
      .enter()
      .append('path')
      .attr('d', (d, i) => line(d) + 'z')
      .attr('stroke', 'black')
      .attr('stroke-dasharray', (d, i) => (i ? 0 : 2))
      .attr('fill', 'none')
    gridPath.attr('d', (d, i) => line(d) + 'z')
    const axis = svg.select('.axis').selectAll('text').data(data[0])
    axis.exit().remove()
    axis
      .enter()
      .append('text')
      .attr('class', 'legend')
      .text(d => d.name)
      .style('font-family', 'sans-serif')
      .style('font-size', '11px')
      .attr('text-anchor', 'middle')
      .attr('dy', '1.5em')
      .attr('x', (d, i) => r * 1.6 * Math.sin((radians / 6) * i) + width / 2)
      .attr(
        'y',
        (d, i) => -r * 1.2 * Math.cos((radians / 6) * i) + height / 2 - 10
      )
    axis.text(d => d.name)
  }, [svg, data, height, width])

  return (
    <div className={styles.container}>
      <svg ref={graphRef} className={styles.graph} />
    </div>
  )
}

export default RadarChart

RadarChart.propTypes = {
  data: PropTypes.array
}
