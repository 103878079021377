export const sendGaEvent = (action, category, label, value = null) => {
  // UA
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    ...(value && { value })
  })
  // GA4
  // window.gtag('event', 'view_item', {
  //   items: [
  //     {
  //       name: title
  //     }
  //   ]
  // })
}
