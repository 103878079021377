import PropTypes from 'prop-types'
import React from 'react'

import MuiSlider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'

import styles from './Slider.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    color: '#333',
    height: 8,
    width: '100%'
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#0D76D6',
    border: '4px solid #FFF',
    marginTop: -10,
    marginLeft: -14,
    '&:focus, &:hover, &:active': {
      boxShadow: 'inherit'
    }
  },
  rail: {
    color: '#333',
    opacity: 1,
    height: 8,
    borderRadius: 4
  },
  mark: {
    backgroundColor: '#333',
    height: 16,
    width: 2,
    marginTop: -8
  }
}))

const marks = [
  {
    value: 50
  }
]

export const Slider = ({
  onChange = () => {},
  labelLeft = '',
  labelRight = '',
  minValue = 0,
  maxValue = 100,
  value = 0
}) => {
  const classes = useStyles()
  return (
    <div className={styles.container}>
      <div className={styles.labelContainer}>
        <div className={styles.minLabel}>{labelLeft}</div>
        <div className={styles.maxLabel}>{labelRight}</div>
      </div>
      <MuiSlider
        className={styles.slider}
        classes={classes}
        track={false}
        marks={marks}
        defaultValue={50.0}
        min={minValue}
        max={maxValue}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

Slider.propTypes = {
  onChange: PropTypes.func,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.number
}
