import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { sendGaEvent } from '../../GaEvent'
import Button from '../ui/Button'
import LineSyncButton from '../ui/LineSyncButton'

import styles from './LineSyncRecommend.module.scss'

export default function LineSyncRecommend() {
  const history = useHistory()
  const { lineUserId } = useSelector(state => state.user.data)

  useEffect(() => {
    if (lineUserId != null) history.push('/')
  }, [history, lineUserId])

  return (
    <div className={styles.container}>
      <div className={styles.recommendBg} />
      <div className={styles.recommendImage} />
      <div className={styles.suggest}>
        ストレスは時々で変化するので定期的な診断がかかせないのじゃ。わしと友達になると、LINEでプチ診断ができるぞい！
      </div>
      <div className={styles.buttons}>
        <LineSyncButton
          onClick={() =>
            sendGaEvent('sync_line_friends', 'friend', 'ログイン後提示')
          }
        />
        <Button
          text='今回はスキップ'
          size='small'
          href='/'
          fullWidth
          onClick={() =>
            sendGaEvent('sync_line_friends', 'friend', 'ログイン後スキップ')
          }
        />
      </div>
    </div>
  )
}
