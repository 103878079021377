import React from 'react'
import { Helmet } from 'react-helmet-async'

import { makeStyles } from '@material-ui/core/styles'

import { sendGaEvent } from '../../GaEvent'
import { AnnounceDoctor } from '../ui/AnnounceDoctor'
import { Button } from '../ui/Button'

import styles from './AppError.module.scss'

const useButtonStyles = makeStyles(theme => ({
  root: { width: 240 }
}))

export function AppError() {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>エラー</title>
      </Helmet>
      <AnnounceDoctor
        text='すまんのぉ。
        どうやらなにかエラーが発生したようじゃ。
        悪いんじゃが、もう一度診断をしてみてもらえるかの？'
      />

      <div className={styles.start}>
        <Button
          text='診断を開始する'
          href='/analysis/'
          color='primary'
          classes={useButtonStyles()}
          onClick={() =>
            sendGaEvent('go_to_analysis', 'analysis', 'エラー画面')
          }
        />
      </div>
      <br />
    </div>
  )
}
export default AppError
