import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styles from './MainMenuItems.module.scss'

export default function MainMenuItems({
  onClickLink = () => {},
  small = false
}) {
  const history = useHistory()
  const openLink = link => {
    history.push(link)
    onClickLink(link)
  }
  const auth = useSelector(state => state.user.auth)
  const isLogin = auth.uid != null

  const MenuItems = [
    { name: 'Home', onClick: () => openLink('/') },
    { name: '診断結果', onClick: () => openLink('/result') },
    { name: 'アドバイス記事一覧', onClick: () => openLink('/article/list') },
    {
      name: 'お気に入り記事一覧',
      onClick: () => openLink('/article/favorites')
    },
    { name: 'うさタイプ一覧', onClick: () => openLink('/typelist') },
    { name: 'サービスコンセプト', onClick: () => openLink('/concept') },
    { name: 'コンタクト', onClick: () => openLink('/contact') },
    { name: '利用規約', onClick: () => openLink('/tos') },
    { name: 'プライバシーポリシー', onClick: () => openLink('/policy') }
  ]
  if (isLogin) {
    MenuItems.push({ name: 'ログアウト', onClick: () => openLink('/logout') })
  } else {
    MenuItems.push({ name: 'ログイン', onClick: () => openLink('/login') })
  }

  return (
    <div className={styles.container}>
      {MenuItems.map(el => (
        <div
          className={classNames(styles.MenuItem, { [styles.small]: small })}
          key={el.name}
          onClick={e => el.onClick()}
        >
          {el.name}
        </div>
      ))}
    </div>
  )
}

MainMenuItems.propTypes = {
  onClickLink: PropTypes.func,
  small: PropTypes.bool
}
