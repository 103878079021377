import React from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import Button from './Button'
import { LineIconWhite } from './ButtonIcons'

import styles from './LineButton.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#06C755'
  }
}))

export function LineSyncButton(props) {
  const classes = useStyles()
  const id = process.env.REACT_APP_LINE_ACCOUNT_ID
  const { syncCode } = useSelector(state => state.user.data)
  const href = `https://line.me/R/oaMessage/${id}/?連携コード［${syncCode}］`

  return (
    <div className={styles.container}>
      <Button
        {...props}
        text='博士と友だちになる'
        classes={classes}
        size='small'
        href={href}
        color='primary'
        startIcon={<LineIconWhite />}
        fullWidth
      />
    </div>
  )
}

export default LineSyncButton
