import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { Slider } from '../ui/Slider'
import SliderImage from './AnswerSliderImages'

import styles from './AnswerSlider.module.scss'

export const AnswerSlider = ({
  onChange = () => {},
  question = {},
  record = {}
}) => {
  const [usaIndex, setUsaIndex] = useState(5)
  const itemValue = question.items[0]
  const { flipAnimation, itemId, minLabel, maxLabel, text } = itemValue
  const value = record[itemId] == null ? 50 : record[itemId] * 100

  useEffect(() => {
    onChange({ [itemId]: value / 100.0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId])

  useEffect(() => {
    const val = flipAnimation ? 100 - value : value
    const idx = parseInt((val / 100.0) * 9)
    setUsaIndex(idx < 8 ? idx : 8)
  }, [value, flipAnimation])

  return (
    <div className={styles.container}>
      <div className={styles.itemText}>{text}</div>
      <div className={styles.usagiContainer}>
        {SliderImage[itemValue.sliderImage].map((image, i) => (
          <img
            key={`usaImg${i}`}
            alt='usaImg'
            src={image}
            className={ClassNames({ [styles.hidden]: usaIndex !== i })}
          />
        ))}
      </div>
      <div className={styles.sliderContainer}>
        <Slider
          onChange={(e, v) => onChange({ [itemId]: v / 100.0 })}
          labelLeft={minLabel}
          labelRight={maxLabel}
          value={value}
        />
      </div>
    </div>
  )
}

AnswerSlider.propTypes = {
  onChange: PropTypes.func,
  question: PropTypes.object,
  record: PropTypes.object
}
