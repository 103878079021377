import { configureStore } from '@reduxjs/toolkit'

import analysisReducer from './analysis'
import appReducer from './app'
import articleReducer from './article'
import instructionReducer from './instruction'
import localStoreReducer from './localStore'
import recordReducer from './record'
import recordHistoryReducer from './recordHistory'
import resultReducer from './result'
import userReducer from './user'

export default configureStore({
  reducer: {
    analysis: analysisReducer,
    app: appReducer,
    article: articleReducer,
    instruction: instructionReducer,
    localStore: localStoreReducer,
    record: recordReducer,
    recordHistory: recordHistoryReducer,
    result: resultReducer,
    user: userReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})
