import 'firebase/auth'

import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { unsubscribeHistory } from '../../stores/recordHistory'
import { unsubscribeUser } from '../../stores/user'

export default function Logout() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    firebase.auth().signOut()
    dispatch(unsubscribeUser())
    dispatch(unsubscribeHistory())
    history.push('/')
    enqueueSnackbar('ログアウトしました')
  }, [dispatch, history, enqueueSnackbar])

  return <></>
}
