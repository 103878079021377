import React from 'react'

import { SvgIcon } from '@material-ui/core'

export const TriangleBackIcon = props => (
  <SvgIcon {...props}>
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.4286 17.9058C15.0916 18.3675 16 17.8931 16 17.0852L16 6.9265C16 6.11634 15.0871 5.64245 14.4245 6.1087L7.1683 11.215C6.60057 11.6145 6.60267 12.4567 7.17238 12.8534L14.4286 17.9058Z'
        fill='black'
      />
    </svg>
  </SvgIcon>
)
export const TriangleNextIcon = props => (
  <SvgIcon {...props}>
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.57142 6.09416C8.90842 5.63252 8 6.10693 8 6.91482V17.0735C8 17.8837 8.91295 18.3575 9.5755 17.8913L16.8317 12.785C17.3994 12.3855 17.3973 11.5433 16.8276 11.1466L9.57142 6.09416Z'
        fill='black'
      />
    </svg>
  </SvgIcon>
)
export const GoogleIcon = props => (
  <SvgIcon {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      width='24px'
      height='24px'
    >
      <path
        fill='#FFC107'
        d='M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z'
      />
      <path
        fill='#FF3D00'
        d='M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z'
      />
      <path
        fill='#4CAF50'
        d='M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z'
      />
      <path
        fill='#1976D2'
        d='M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z'
      />
    </svg>
  </SvgIcon>
)
export const FacebookIcon = props => (
  <SvgIcon {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      width='24px'
      height='24px'
    >
      <path fill='#3f51b5' d='M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z' />
      <path
        fill='#fff'
        d='M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z'
      />
    </svg>
  </SvgIcon>
)

export const TwitterIcon = props => (
  <SvgIcon>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      width='24px'
      height='24px'
    >
      <path fill='#03a9f4' d='M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z' />
      <path
        fill='#fff'
        d='M36,17.12c-0.882,0.391-1.999,0.758-3,0.88c1.018-0.604,2.633-1.862,3-3 c-0.951,0.559-2.671,1.156-3.793,1.372C31.311,15.422,30.033,15,28.617,15C25.897,15,24,17.305,24,20v2c-4,0-7.9-3.047-10.327-6 c-0.427,0.721-0.667,1.565-0.667,2.457c0,1.819,1.671,3.665,2.994,4.543c-0.807-0.025-2.335-0.641-3-1c0,0.016,0,0.036,0,0.057 c0,2.367,1.661,3.974,3.912,4.422C16.501,26.592,16,27,14.072,27c0.626,1.935,3.773,2.958,5.928,3c-1.686,1.307-4.692,2-7,2 c-0.399,0-0.615,0.022-1-0.023C14.178,33.357,17.22,34,20,34c9.057,0,14-6.918,14-13.37c0-0.212-0.007-0.922-0.018-1.13 C34.95,18.818,35.342,18.104,36,17.12'
      />
    </svg>
  </SvgIcon>
)

export const LineIconWhite = props => (
  <SvgIcon {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      width='48px'
      height='48px'
    >
      <path
        className='st0'
        d='M23.5,10.5c0-5.1-5.1-9.3-11.5-9.3S0.5,5.4,0.5,10.5c0,4.6,4.1,8.4,9.6,9.2c0.4,0.1,0.9,0.2,1,0.6
	c0.1,0.3,0.1,0.7,0,1c0,0-0.1,0.8-0.2,1c0,0.3-0.2,1.1,1,0.6c1.2-0.5,6.6-3.9,9-6.7h0C22.7,14.4,23.5,12.6,23.5,10.5z M7.5,13.6H5.2
	c-0.3,0-0.6-0.3-0.6-0.6V8.4c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v4h1.7c0.3,0,0.6,0.3,0.6,0.6C8.1,13.3,7.8,13.6,7.5,13.6z
	 M9.9,13c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6V8.4c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6V13z M15.4,13
	c0,0.3-0.2,0.5-0.4,0.6c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2l-2.3-3.2V13c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6V8.4
	c0-0.3,0.2-0.5,0.4-0.6c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.5,0.2l2.3,3.2V8.4c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6V13z M19,10.1
	c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6h-1.7v1.1H19c0.3,0,0.6,0.3,0.6,0.6s-0.3,0.6-0.6,0.6h-2.3c-0.3,0-0.6-0.3-0.6-0.6
	v-2.3c0,0,0,0,0,0v0V8.4c0,0,0,0,0,0c0-0.3,0.3-0.6,0.6-0.6H19c0.3,0,0.6,0.3,0.6,0.6S19.4,9,19,9h-1.7v1.1L19,10.1L19,10.1z'
      />
    </svg>
  </SvgIcon>
)

export const UsagiIcon = props => (
  <SvgIcon {...props}>
    <svg
      width='19'
      height='20'
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.0055 19.7611C15.6616 18.84 18.3891 17.0372 18.3891 13.0406C18.3891 9.96037 16.6534 7.44714 14.0593 6.16506V1.94625C14.0593 0.874773 13.1751 0 12.092 0C11.009 0 10.1248 0.877087 10.1248 1.94625V5.57263C9.91893 5.56105 9.71074 5.55411 9.50022 5.55411C9.24291 5.55411 8.98794 5.56568 8.7353 5.58188V1.94625C8.7353 0.874773 7.85109 0 6.76805 0C5.68501 0 4.8008 0.874773 4.8008 1.94625V6.23912C2.28618 7.53739 0.611328 10.0159 0.611328 13.0429C0.611328 17.4399 3.87449 19.4811 8.13414 19.9439C8.13414 19.9439 9.18443 20.0203 9.77624 19.9948C9.77624 19.9948 11.0885 19.9925 12.0055 19.7611Z'
        fill='white'
      />
    </svg>
  </SvgIcon>
)

export const LikeIconOff = props => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.9978 27.7861L19.4195 28.6019C19.7651 28.8469 20.2275 28.8475 20.5737 28.6036L19.9978 27.7861ZM19.9978 27.7861C20.5737 28.6036 20.5738 28.6035 20.574 28.6034L20.5743 28.6032L20.5754 28.6024L20.5791 28.5998L20.5925 28.5903L20.6423 28.5548C20.6855 28.5239 20.7483 28.4789 20.8282 28.4207C20.9882 28.3045 21.2174 28.1361 21.4973 27.9249C22.0565 27.503 22.8213 26.9084 23.6428 26.2176C25.2495 24.8667 27.2023 23.0431 28.1685 21.3867C29.206 19.6081 29.2263 17.5453 28.486 15.9111C27.7408 14.2658 26.1877 13.0023 24.1169 13.0002C23.0011 12.99 21.9104 13.3325 21.0008 13.979C20.626 14.2454 20.2899 14.5577 19.9991 14.9068C19.7089 14.5577 19.3733 14.2453 18.9989 13.9789C18.0898 13.332 16.9993 12.9894 15.8836 13.0002C13.8084 13.0024 12.2561 14.2733 11.5124 15.9199C10.7738 17.5554 10.794 19.6191 11.8339 21.3893C12.7995 23.0332 14.7495 24.8559 16.3544 26.209C17.175 26.9008 17.9388 27.4972 18.4974 27.9206C18.7769 28.1325 19.0058 28.3017 19.1655 28.4183C19.2454 28.4767 19.308 28.522 19.3511 28.553L19.4008 28.5886L19.4142 28.5981L19.4179 28.6007L19.419 28.6015L19.4193 28.6017C19.4194 28.6018 19.4195 28.6019 19.9978 27.7861Z'
      fill='white'
      stroke='white'
      strokeWidth='2'
      strokeLinejoin='round'
    />
    <path
      d='M19.9978 27.7861C19.9978 27.7861 25.566 23.8635 27.3047 20.8828C29.0435 17.9021 27.3513 14.0002 24.1118 14.0002C23.2053 13.991 22.3191 14.2689 21.5801 14.7941C20.8411 15.3193 20.2872 16.0649 19.9978 16.9241C19.7101 16.0649 19.1574 15.319 18.4191 14.7937C17.6808 14.2683 16.795 13.9905 15.889 14.0002C12.6444 14.0002 10.9574 17.9228 12.6961 20.8828C14.4349 23.8428 19.9978 27.7861 19.9978 27.7861Z'
      fill='white'
      stroke='#C3B0A2'
      strokeWidth='1.5'
      strokeLinejoin='round'
    />
  </svg>
)

export const LikeIconOn = props => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.9978 27.7861L19.4195 28.6019C19.7651 28.8469 20.2275 28.8475 20.5737 28.6036L19.9978 27.7861ZM19.9978 27.7861C20.5737 28.6036 20.5738 28.6035 20.574 28.6034L20.5743 28.6032L20.5754 28.6024L20.5791 28.5998L20.5925 28.5903L20.6423 28.5548C20.6855 28.5239 20.7483 28.4789 20.8282 28.4207C20.9882 28.3045 21.2174 28.1361 21.4973 27.9249C22.0565 27.503 22.8213 26.9084 23.6428 26.2176C25.2495 24.8667 27.2023 23.0431 28.1685 21.3867C29.206 19.6081 29.2263 17.5453 28.486 15.9111C27.7408 14.2658 26.1877 13.0023 24.1169 13.0002C23.0011 12.99 21.9104 13.3325 21.0008 13.979C20.626 14.2454 20.2899 14.5577 19.9991 14.9068C19.7089 14.5577 19.3733 14.2453 18.9989 13.9789C18.0898 13.332 16.9993 12.9894 15.8836 13.0002C13.8084 13.0024 12.2561 14.2733 11.5124 15.9199C10.7738 17.5554 10.794 19.6191 11.8339 21.3893C12.7995 23.0332 14.7495 24.8559 16.3544 26.209C17.175 26.9008 17.9388 27.4972 18.4974 27.9206C18.7769 28.1325 19.0058 28.3017 19.1655 28.4183C19.2454 28.4767 19.308 28.522 19.3511 28.553L19.4008 28.5886L19.4142 28.5981L19.4179 28.6007L19.419 28.6015L19.4193 28.6017C19.4194 28.6018 19.4195 28.6019 19.9978 27.7861Z'
      fill='#F18181'
      stroke='white'
      strokeWidth='2'
      strokeLinejoin='round'
    />
    <path
      d='M19.9978 27.7861C19.9978 27.7861 25.566 23.8635 27.3047 20.8828C29.0435 17.9021 27.3513 14.0002 24.1118 14.0002C23.2053 13.991 22.3191 14.2689 21.5801 14.7941C20.8411 15.3193 20.2872 16.0649 19.9978 16.9241C19.7101 16.0649 19.1574 15.319 18.4191 14.7937C17.6808 14.2683 16.795 13.9905 15.889 14.0002C12.6444 14.0002 10.9574 17.9228 12.6961 20.8828C14.4349 23.8428 19.9978 27.7861 19.9978 27.7861Z'
      fill='#F18181'
      stroke='#F18181'
      strokeWidth='1.5'
      strokeLinejoin='round'
    />
  </svg>
)
