import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import MainMenuItems from './MainMenuItems'

import styles from './Footer.module.scss'

export default function Footer({ footerEnabled = true }) {
  if (!footerEnabled) return <></>
  return (
    <div className={styles.container}>
      <div className={styles.menuContainer}>
        <MainMenuItems small />
      </div>

      <div className={styles.copyContainer}>
        <Link to='/'>
          <div className={styles.logo} />
        </Link>
        <div className={styles.copyright}>
          Copyright © Usabara All Rights Reserved
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  footerEnabled: PropTypes.bool
}
