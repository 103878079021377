import React, { useEffect, useState } from 'react'

import LoginGuide from './LoginGuide'

const messages = [
  '',
  'ログインすると、今後\nお気に入りボタンが\n使えるようになりますぞ',
  'ログインすると、今後\nコメント機能が使えるように\nなる予定じゃ',
  'ログインすると、\n最適なタイミングで\n再診断をお伝えしますぞ',
  'ログインすると、\n新しい記事が出た時に\n通知が受け取れますぞ'
]

export const LoginRandomGuide = () => {
  const [message, setMessage] = useState('')

  useEffect(() => {
    const idx = parseInt(Math.random() * 5)
    setMessage(messages[idx])
  }, [])

  return <LoginGuide message={message} />
}

export default LoginRandomGuide
