import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Button from '../ui/Button'
import { ChatBaloonDoctor } from '../ui/ChatBaloon'

import styles from './ArticleSuggestDoctor.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: 160,
    height: 32
  }
}))
export const ArticleSuggestDoctor = ({ articles = [] }) => {
  const classes = useStyles()
  if (articles.length === 0) return <></>
  const topContent = articles[0]
  return (
    <div className={styles.container}>
      <div className={styles.topContent}>
        <ChatBaloonDoctor>
          <div className={styles.doctorText}>
            そんな君のタイプにぴったりの解消法はこれじゃ！
          </div>
          <Link to={`/article/${topContent.copingContentsId}`}>
            <div className={styles.article}>
              <div className={styles.title}>{topContent.title}</div>
              <div className={styles.thumb}>
                <img
                  alt='thumb'
                  src={`${process.env.PUBLIC_URL}/images/article/${topContent.thumbImage}`}
                />
              </div>
            </div>
          </Link>
          <div className={styles.readBtn}>
            <Button
              text='読んでみる'
              classes={classes}
              size='small'
              color='primary'
              href={`/article/${topContent.copingContentsId}`}
              fullWidth
            />
          </div>
        </ChatBaloonDoctor>
      </div>
    </div>
  )
}

ArticleSuggestDoctor.propTypes = {
  articles: PropTypes.array
}
export default ArticleSuggestDoctor
