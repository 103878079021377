import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useScrollTracker } from 'react-scroll-tracker'

import { fetchArticle } from '../../stores/article'
import LoginRandomGuide from '../app/LoginRandomGuide'
import Button from '../ui/Button'
import { TriangleBackIcon } from '../ui/ButtonIcons'
import {
  amazon,
  box,
  h1,
  h2,
  h3,
  image,
  li,
  paragraph,
  student,
  teacher
} from './ArticleItems'
import FavoriteSection from './FavoriteSection'
import RecommendSection from './RecommendSection'

import styles from './Article.module.scss'

export default function Article({ articleId }) {
  const dispatch = useDispatch()
  const { article } = useSelector(state => state.article)

  const history = useHistory()
  useEffect(() => {
    dispatch(fetchArticle(articleId))
  }, [dispatch, articleId])

  // GA Scroll tracking
  const location = useLocation()
  useScrollTracker([25, 50, 75, 90], ({ scrollY }) => {
    window.gtag('event', `scroll_${scrollY}`, {
      event_category: 'scroll',
      event_label: location.pathname
    })
  })

  const renderBlock = block => {
    switch (block.type) {
      case 'paragraph':
      case 'to_do':
      case 'toggle':
        return paragraph(block.textObj)
      case 'image':
        return image(block.text)
      case 'student':
        return student(block.textObj)
      case 'teacher':
        return teacher(block.textObj)
      case 'heading_1':
        return h1(block.textObj)
      case 'heading_2':
        return h2(block.textObj)
      case 'heading_3':
        return h3(block.textObj)
      case 'bulleted_list_item':
      case 'numbered_list_item':
        return li(block.textObj)
      case 'amazon':
        return amazon(block.text, block.image, block.url)
      case 'box':
        return box(
          block.text,
          block.children.map(b => renderBlock(b))
        )
      default:
        return <></>
    }
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{article.title}</title>
      </Helmet>
      <motion.div
        animate={{ opacity: 1.0 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Button
          startIcon={<TriangleBackIcon />}
          text='戻る'
          size='small'
          variant='text'
          onClick={() => history.goBack()}
        />
        <img
          alt='title_image'
          src={`${process.env.PUBLIC_URL}/images/article/${article.titleImage}`}
        />
        <div className={styles.body}>
          <h2> {article.title} </h2>
          {article.body == null ? (
            <></>
          ) : (
            article.body.map((block, i) => (
              <div key={`block${i}`} className={styles.block}>
                {renderBlock(block)}
              </div>
            ))
          )}{' '}
        </div>
        <FavoriteSection articleId={articleId} />
        <div className={styles.separator}>・・・</div>
        <RecommendSection articleId={articleId} />
        <LoginRandomGuide />
      </motion.div>
    </div>
  )
}

Article.propTypes = {
  articleId: PropTypes.number
}
