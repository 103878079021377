import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from './components/App'
import reportWebVitals from './reportWebVitals'
import store from './stores/'

import './index.scss'

ReactDOM.render(
  <SnackbarProvider maxSnack={3}>
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  </SnackbarProvider>,
  document.getElementById('root')
)

reportWebVitals(console.log)
