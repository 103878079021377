import React from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import { sendGaEvent } from '../../GaEvent'
import Button from '../ui/Button'

import styles from './LoginStatus.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    color: '#AC917B'
  }
}))

export default function LoginStatus() {
  const buttonStyles = useStyles()

  const { uid, displayName, email } = useSelector(state => state.user.auth)

  return (
    <div className={styles.container}>
      <div className={styles.authIcon} />
      <div className={styles.authStatus}>
        {uid == null ? (
          <>
            <Button
              text='ログイン'
              href='/login'
              classes={buttonStyles}
              fullWidth
              onClick={() =>
                sendGaEvent('go_to_login', 'login', 'ログインステータス')
              }
            />
          </>
        ) : (
          <div className={styles.nameContainer}>
            {displayName && <div className={styles.name}>{displayName}</div>}
            <div className={styles.email}>{email}</div>
          </div>
        )}
      </div>
    </div>
  )
}
