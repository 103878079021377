// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { sendGaEvent } from '../../GaEvent'
import { toggleFavoriteArticle } from '../../stores/user'
import Button from '../ui/Button'
import { LikeIconOff, LikeIconOn } from '../ui/ButtonIcons'
import DoctorDialog from '../ui/DoctorDialog'

import styles from './FavoriteSection.module.scss'

export function FavoriteSection({ articleId }) {
  const dispatch = useDispatch()
  const { favoriteArticleIds } = useSelector(state => state.user.data)
  const [isMyFavorite, setIsMyFavorite] = useState(false)
  const { auth } = useSelector(state => state.user)
  const dialogRef = useRef()
  const history = useHistory()

  useEffect(() => {
    if (favoriteArticleIds.includes(articleId)) {
      setIsMyFavorite(true)
    } else {
      setIsMyFavorite(false)
    }
  }, [favoriteArticleIds, articleId])

  const toggleFavorite = e => {
    const action = isMyFavorite ? 'remove_favorite' : 'add_favorite'
    sendGaEvent(action, 'favorite', auth.uid == null ? 'no_login' : articleId)
    if (auth.uid == null) {
      dialogRef.current.handleOpen(e)
    } else {
      dispatch(toggleFavoriteArticle(articleId))
    }
  }

  const onClickLogin = () => {
    sendGaEvent('go_to_signup', 'login', '記事のお気に入り追加(Section)')
    history.push(`/signup`)
  }

  return (
    <div className={styles.container}>
      <DoctorDialog
        title='ログインが必要です'
        text='記事をお気に入りに追加するには、ログインが必要じゃ'
        ref={dialogRef}
        buttons={[
          { name: 'ログイン', value: onClickLogin },
          { name: 'キャンセル', value: () => {} }
        ]}
      />
      <div className={styles.buttons}>
        {isMyFavorite ? (
          <Button
            text='お気に入り'
            onClick={toggleFavorite}
            startIcon={<LikeIconOn />}
            color='secondary'
            fullWidth
          />
        ) : (
          <Button
            text='お気に入りに追加'
            onClick={toggleFavorite}
            startIcon={<LikeIconOff />}
            color='secondary'
            fullWidth
          />
        )}
        <Button
          text='お気に入り一覧'
          href='/article/favorites'
          color='secondary'
          fullWidth
        />
      </div>
    </div>
  )
}

FavoriteSection.propTypes = {
  articleId: PropTypes.number
}

export default FavoriteSection
