// ==== analyze format ====
// inputSample: {
//   logicVersion: 1,
//   data: [
//     { itemId: "tipij1n001", value: 0.5 },
//     ...
//   ]
// }
// outputSample: {
//   coping: [
//     { copingId: 1, zAndAllMean: 2.0595 },
//     ...
//   ],
//   precision: 0.866535103,
//   sixScales: [
//     { scaleId: "cissSf1", zScore: 1.032072251 },
//     { scaleId: "cissSf3", zScore: 0.269687591 },
//     { scaleId: "cissSf5", zScore: -2.162767281 },
//     { scaleId: "cissSf6", zScore: 0.477313846 },
//     { scaleId: "tac246", zScore: -1.734831657 },
//     { scaleId: "tac247", zScore: -0.730799618 }
//   ],
//   stressLevel: 26.3,
//   stressLevelRank: 95,
//   type: { typeId: 5 }
// }
// ========================

// ==== analyzePrecision format ====
// inputSample: {
//   logicVersion: 1,
//   data: [
//     { itemId: "tipij1n001", value: 0.5 },
//     ...
//   ]
// }
// outputSample: {
//   precision: 0.866535103
// }
// ========================

import moment from 'moment'

import copingContentsDataJson from './copingContentsData.json'
import copingDataJson from './copingData.json'
import copingModelCoeffJson from './copingModelCoeff.json'
import itemDataJson from './itemData.json'
import percentileDataJson from './percentileData.json'
import scaleDataJson from './scaleData.json'
import typeDataJson from './typeData.json'

const copingContentsRandomizeCoeff = 0.2 // Range 0 ~ 1

const randomSeedRange = ({ max = 1, min = 0, seed }) => {
  let x = 123456789
  let y = 362436069
  let z = 521288629
  let w = seed
  const t = x ^ (x << 11)
  x = y
  y = z
  z = w
  const rand = (w = w ^ (w >>> 19) ^ (t ^ (t >>> 8)))
  const randNorm = (Math.abs(rand) % 1000001) / 1000000
  return min + randNorm * (max - min)
}

const validate = input => {
  const data = input.data.reduce((accum, current) => {
    const { itemId } = current
    if (!itemDataJson.data.some(item => item.itemId === itemId)) {
      console.warn('[Analyze validation]: "itemId" is invalid.')
      return accum
    }
    let { value } = current
    if (value < 0 || value > 1) {
      console.warn('[Analyze validation]: A value is out of range(0-1).')
      value = Math.max(value, 0)
      value = Math.min(value, 1)
    }
    accum.push({ itemId, value })
    return accum
  }, [])
  return { ...input, data }
}

const computeScaleBaseData = input => {
  const output = {}
  scaleDataJson.data.forEach(({ scaleId }) => {
    output[scaleId] = {}
  })
  // restoredResult, count
  itemDataJson.data.forEach(
    ({ defaultValue, inversion, itemId, max, min, scaleId }) => {
      const answerData = input.data.find(element => element.itemId === itemId)
      // restoredResult
      let current = answerData == null ? defaultValue : answerData.value
      current = inversion ? 1 - current : current
      current = min + (max - min) * current
      const prevRestoredResult = output[scaleId].restoredResult || 0
      output[scaleId].restoredResult = prevRestoredResult + current
      // count
      output[scaleId].count = (output[scaleId].count || 0) + 1
      const prevAnswerCount = output[scaleId].answerCount || 0
      output[scaleId].answerCount =
        prevAnswerCount + (answerData == null ? 0 : 1)
    }
  )
  // zScore, cSum
  scaleDataJson.data.forEach(
    ({ mu, name, scaleId, sigma, wellbeingLowStressModelCoeff }) => {
      const zScore = (output[scaleId].restoredResult - mu) / sigma
      const cSum = zScore * wellbeingLowStressModelCoeff
      output[scaleId] = { ...output[scaleId], name, zScore, cSum }
    }
  )
  return output
}

const computeStressLevel = scaleBaseData => {
  const sum = scaleDataJson.data.reduce((accum, current) => {
    return accum + scaleBaseData[current.scaleId].cSum
  }, 0)
  return 50 - 10 * (sum + scaleDataJson.intercept)
}

const computeCoping = scaleBaseData => {
  // const startTime = performance.now()
  const copingModel = {}
  // Parse Json
  copingDataJson.data.forEach(({ copingId }) => {
    const columnIndex = copingModelCoeffJson.copingId.findIndex(
      element => element === copingId
    )
    const coeff = {}
    scaleDataJson.data.forEach(({ scaleId }) => {
      const row = copingModelCoeffJson[scaleId]
      if (row == null) return
      coeff[scaleId] = row[columnIndex]
    })
    copingModel[copingId] = {
      coeff,
      corr: copingModelCoeffJson.corr[columnIndex],
      intercept: copingModelCoeffJson.intercept[columnIndex],
      mu: copingModelCoeffJson.mu[columnIndex],
      muz: copingModelCoeffJson.muz[columnIndex],
      sigma: copingModelCoeffJson.sigma[columnIndex]
    }
  })
  // console.log('> ', performance.now() - startTime)
  return copingDataJson.data.map(({ copingId, name }) => {
    const { coeff, intercept, mu, muz, sigma } = copingModel[
      copingId.toString()
    ]
    const predicted =
      Object.keys(coeff).reduce((accum, key) => {
        return accum + coeff[key] * scaleBaseData[key].zScore
      }, 0) + intercept
    // console.log('predicted', predicted)
    const subZ = (predicted - mu) / sigma
    const zAndAllMean = subZ + muz
    return { copingId, name, zAndAllMean }
  })
}

const computeCopingStyleVariables = coping => {
  const copingStyleVariables = {}
  const denominators = {}
  copingDataJson.data.forEach(({ copingId, styleVariables }, index) => {
    Object.keys(styleVariables).forEach(key => {
      if (copingStyleVariables[key] == null) {
        copingStyleVariables[key] = 0
        denominators[key] = 0
      }
      let zAndAllMean = 0
      if (copingId !== coping[index].copingId) {
        console.log('The coping index is not sorted.')
        zAndAllMean = coping.find(element => copingId === element.copingId)
          .zAndAllMean
      } else {
        zAndAllMean = coping[index].zAndAllMean
      }
      copingStyleVariables[key] += styleVariables[key] * zAndAllMean
      denominators[key] += parseFloat(styleVariables[key])
    })
  })
  Object.keys(copingStyleVariables).forEach(key => {
    copingStyleVariables[key] /= denominators[key]
  })
  return copingStyleVariables
}

const computeCopingContents = copingStyleVariables => {
  const personalDist = Math.sqrt(
    Object.keys(copingStyleVariables).reduce((accum, key) => {
      const d = copingStyleVariables[key] > 0 ? copingStyleVariables[key] : 0
      return accum + Math.pow(d, 2)
    }, 0)
  )
  if (personalDist === 0) {
    console.warn('Personal copingStyleVariables vector is Zero.')
  }
  return copingContentsDataJson.data.map(
    ({
      copingContentsId,
      title,
      tips,
      thumbImage,
      styleVariables,
      categoryLarge,
      categoryMiddle
    }) => {
      const copingContentsDist = Math.sqrt(
        Object.keys(styleVariables).reduce((accum, key) => {
          return accum + Math.pow(styleVariables[key], 2)
        }, 0)
      )
      if (copingContentsDist === 0) {
        console.warn('CopingContents StyleVariables vector is Zero.')
      }
      const cos =
        Object.keys(styleVariables).reduce((accum, key) => {
          const personalElement =
            copingStyleVariables[key] > 0 ? copingStyleVariables[key] : 0
          return accum + personalElement * styleVariables[key]
        }, 0) /
        (personalDist * copingContentsDist)
      // It is mixing random numbers. The seed numbers are date.
      const rand = randomSeedRange({
        seed: moment().startOf('day').valueOf() * (copingContentsId + 1)
      })
      return {
        copingContentsId,
        title,
        tips,
        thumbImage,
        categoryLarge,
        categoryMiddle,
        cos:
          cos * (1 - copingContentsRandomizeCoeff) +
          rand * copingContentsRandomizeCoeff
      }
    }
  )
}

const computePrecision = scaleBaseData => {
  const { precisionCoeff1, precisionCoeff2 } = scaleDataJson
  const mid = scaleDataJson.data.reduce((accum, current) => {
    const { scaleId, weight } = current
    const { count, answerCount } = scaleBaseData[scaleId]
    return accum + weight * (answerCount / count)
  }, 0)
  return precisionCoeff1 + precisionCoeff2 * mid
}

const computeType = scaleBaseData => {
  const isAnswered = typeDataJson.data.reduce((accum, current) => {
    return accum || scaleBaseData[current.refScaleId].answerCount !== 0
  }, false)
  if (!isAnswered) {
    console.log('Type question is not answered.')
    return {}
  }
  return typeDataJson.data.reduce((accum, current) => {
    if (
      scaleBaseData[current.refScaleId].zScore > 0 !==
      current.refIsScalePositive
    )
      return accum
    const az = Math.abs(scaleBaseData[accum.refScaleId].zScore)
    const cz = Math.abs(scaleBaseData[current.refScaleId].zScore)
    return az > cz ? accum : current
  })
}

const computeSixScales = scaleBaseData => {
  const isAnswered = typeDataJson.data.reduce((accum, current) => {
    return accum || scaleBaseData[current.refScaleId].answerCount !== 0
  }, false)
  if (!isAnswered) {
    console.log('Type question is not answered.')
    return []
  }
  return typeDataJson.data.reduce((accum, current) => {
    if (!current.refIsScalePositive) return accum
    const { name, zScore } = scaleBaseData[current.refScaleId]
    accum.push({ name, scaleId: current.refScaleId, zScore })
    return accum
  }, [])
}

const computeStressLevelRank = scaleBaseData => {
  const total =
    scaleDataJson.data.reduce((accum, current) => {
      return accum + scaleBaseData[current.scaleId].cSum
    }, 0) + scaleDataJson.intercept
  const output = percentileDataJson.data.reduce((out, current) => {
    return Math.abs(current.value - total) < Math.abs(out.value - total)
      ? current
      : out
  })
  return output.rank
}

export const analyze = input => {
  input = validate(input)
  // console.log('itemDataJson', itemDataJson.data)
  // console.log('scaleDataJson', scaleDataJson.data)
  // console.log('copingDataJson', copingDataJson)

  const scaleBaseData = computeScaleBaseData(input)
  // console.log('scaleBaseData', scaleBaseData)

  const stressLevel = computeStressLevel(scaleBaseData)
  // console.log('stressLevel', stressLevel)
  const coping = computeCoping(scaleBaseData)
  // console.log('coping', coping)
  const copingStyleVariables = computeCopingStyleVariables(coping)
  // console.log('copingStyleVariables', copingStyleVariables)
  const copingContents = computeCopingContents(copingStyleVariables)
  // copingContents.sort((a, b) => b.cos - a.cos)
  // console.log('copingContents', copingContents)
  const precision = computePrecision(scaleBaseData)
  // console.log('precision', precision)
  const sixScales = computeSixScales(scaleBaseData)
  // console.log('sixScales', sixScales)
  const type = computeType(scaleBaseData)
  // console.log('type', type)
  const stressLevelRank = computeStressLevelRank(scaleBaseData)
  // console.log('stressLevelRank', stressLevelRank)
  return {
    coping,
    copingContents,
    precision,
    sixScales,
    stressLevel,
    type,
    stressLevelRank
  }
}

export const analyzePrecision = input => {
  input = validate(input)
  const scaleBaseData = computeScaleBaseData(input)
  return computePrecision(scaleBaseData)
}
