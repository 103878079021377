import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import { sendGaEvent } from '../../GaEvent'
import { ChatBaloonDoctor, ChatBaloonStudenr } from '../ui/ChatBaloon'

import styles from './ArticleItems.module.scss'

export const image = image => (
  <img
    alt='img'
    src={`${process.env.PUBLIC_URL}/images/article/${cleanupText(image)}`}
  />
)

export const list = list => (
  <div className={styles.listSection}>
    {list.map(item => (
      <li key={item}>{item}</li>
    ))}
  </div>
)

export const reference = list => (
  <div className={styles.referenceSection}>
    <h3>出典</h3>
    {list.map((item, i) => (
      <p key={`item${i}`}>{item}</p>
    ))}
  </div>
)

export const student = textObj => (
  <div className={styles.student}>
    <ChatBaloonStudenr>{parseTextObject(textObj)}</ChatBaloonStudenr>
  </div>
)

export const teacher = textObj => (
  <div className={styles.teacher}>
    <ChatBaloonDoctor>{parseTextObject(textObj)}</ChatBaloonDoctor>
  </div>
)

export const paragraph = textObj => <p>{parseTextObject(textObj)}</p>
export const h1 = textObj => <h1>{parseTextObject(textObj)}</h1>
export const h2 = textObj => <h2>{parseTextObject(textObj)}</h2>
export const h3 = textObj => <h3>{parseTextObject(textObj)}</h3>
export const li = textObj => (
  <ul>
    <li>{parseTextObject(textObj)}</li>
  </ul>
)

export const amazon = (title, image, url) => {
  return (
    <div className={styles.amazonLink}>
      <a href={url} onClick={() => sendGaEvent('go_to_shop', 'amazon', title)}>
        <div className={styles.amzThumbnail}>
          <img alt='Product' src={image} />
        </div>
        <div className={styles.amzTitle}>{title}</div>
      </a>
    </div>
  )
}

export const box = (title, children) => (
  <div className={styles.box}>
    <div className={styles.title}>{title}</div>
    {children.map((child, i) => (
      <div key={`box-child${i}`}>{child}</div>
    ))}
  </div>
)

const textColor = {
  default: 'color:#333;',
  gray: 'color:#8A8A8A;',
  brown: 'color:#613C33;',
  orange: 'color:#CC772D;',
  yellow: 'color:#D7AB33;',
  green: 'color:#2F7769;',
  blue: 'color:#266A93;',
  purple: 'color:#5F3B9E;',
  pink: 'color:#9E266F;',
  red: 'color:#C6463F;',
  gray_background: 'background-color:#EBECED;',
  brown_background: 'background-color:#E8E5E3;',
  orange_background: 'background-color:#F7EBDF;',
  yellow_background: 'background-color:#FAF3DD;',
  green_background: 'background-color:#E0ECEA;',
  blue_background: 'background-color:#E0EBF0;',
  purple_background: 'background-color:#E9E4F1;',
  pink_background: 'background-color:#F0E0EA;',
  red_background: 'background-color:#F7E5E4;'
}

const cleanupText = text => {
  let out = text
  out = out.replace('T:', '')
  out = out.replace('S:', '')
  out = out.replace('IMG:', '')
  out = out.replace('BOX:', '')
  return out
}

const parseTextObject = textObj => {
  if (textObj.length === 0) return ReactHtmlParser('<br/>')
  let out = ''
  textObj.forEach(obj => {
    let styleStr = ''
    const { bold, italic, strikethrough, underline, color } = obj.annotations
    styleStr += textColor[color]
    if (bold) styleStr += 'font-weight:bold;'
    if (italic) styleStr += 'font-style:italic;'
    if (strikethrough) styleStr += 'text-decoration:line-through;'
    if (underline) styleStr += 'text-decoration:underline;'
    out += `<span style="${styleStr}">${cleanupText(obj.text.content)}</span>`

    if (obj.href != null) {
      out = `<a href="${obj.href}">${out}</a>`
    }
  })

  return ReactHtmlParser(out)
}
