import 'firebase/auth'

import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import { sendGaEvent } from '../../GaEvent'
import { saveState, setIsLoggingIn } from '../../stores/localStore'
import Button from '../ui/Button'
import { FacebookIcon, GoogleIcon, TwitterIcon } from '../ui/ButtonIcons'
import TextInput from '../ui/TextInput'

import styles from './Login.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiButton-startIcon': {
      position: 'absolute',
      left: '24px'
    }
  }
}))

export default function Login({ signup = false }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { uid, providerData } = useSelector(state => state.user.auth)
  const { isLoggingIn } = useSelector(state => state.localStore)
  const { enqueueSnackbar } = useSnackbar()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [signupMode, setSignupMode] = useState(signup)
  const [resetPassMode, setResetPassMode] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const buttonStyles = useStyles()

  const setLoggingIn = useCallback(
    async bool => {
      await dispatch(setIsLoggingIn(bool))
      await dispatch(saveState())
    },
    [dispatch]
  )

  useEffect(() => {
    if (uid != null) {
      history.push('/linesync')
      const provider =
        providerData != null &&
        providerData.length !== 0 &&
        providerData[0].providerId != null
          ? providerData[0].providerId
          : ''
      setLoggingIn(false)
      sendGaEvent('complete_login', 'login', provider)
      enqueueSnackbar('ログインに成功しました', { variant: 'success' })
    }
    // Timeout to avoid being locked in "logging in" state
    const timeout = setTimeout(() => setLoggingIn(false), 6000)
    return () => clearTimeout(timeout)
  }, [uid, history, enqueueSnackbar, setLoggingIn])

  const clearMessage = () => {
    setErrorMessage('')
  }

  const onChangeSignupMode = bool => {
    clearMessage()
    setSignupMode(bool)
  }

  const onChangeResetPassMode = bool => {
    clearMessage()
    setResetPassMode(bool)
  }

  const snsLogin = async provider => {
    try {
      clearMessage()
      await setLoggingIn(true)
      await firebase.auth().signInWithRedirect(provider)
    } catch (error) {
      setLoggingIn(false)
      sendGaEvent('error_login', 'login', error.message)
      setErrorMessage(error.message)
    }
  }

  const onClickLogin = async e => {
    try {
      clearMessage()
      setLoggingIn(true)
      sendGaEvent('submit_login', 'login', 'Email')
      await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (error) {
      setLoggingIn(false)
      sendGaEvent('error_login', 'login', error.message)
      setErrorMessage(error.message)
    }
  }

  const onClickSignUp = async e => {
    try {
      clearMessage()
      await firebase.auth().createUserWithEmailAndPassword(email, password)
      setSignupMode(false)
      enqueueSnackbar('アカウントの作成に成功しました', { variant: 'success' })
    } catch (error) {
      sendGaEvent('error_login', 'login', error.message)
      setErrorMessage(error.message)
    }
  }

  const onClickGoogleLogin = e => {
    sendGaEvent('submit_login', 'login', 'Google')
    const provider = new firebase.auth.GoogleAuthProvider()
    snsLogin(provider)
  }

  const onClickFacebookLogin = e => {
    sendGaEvent('submit_login', 'login', 'Facebook')
    const provider = new firebase.auth.FacebookAuthProvider()
    provider.addScope('public_profile')
    snsLogin(provider)
  }

  const onClickTwitterLogin = e => {
    sendGaEvent('submit_login', 'login', 'Twitter')
    const provider = new firebase.auth.TwitterAuthProvider()
    snsLogin(provider)
  }

  const onClickResetPass = async e => {
    try {
      clearMessage()
      await firebase.auth().sendPasswordResetEmail(email)
      enqueueSnackbar('パスワードリセット用メールを送信しました')
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const button = (text, onClick, color = 'default', icon = null) => (
    <Button
      color={color}
      text={text}
      onClick={onClick}
      classes={buttonStyles}
      startIcon={icon}
      fullWidth
    />
  )

  const renderEmailInput = () => (
    <TextInput
      label='Eメール'
      onChange={e => setEmail(e.target.value)}
      value={email}
      fullWidth
    />
  )

  const renderPasswordInput = () => (
    <TextInput
      label='パスワード'
      type='password'
      onChange={e => setPassword(e.target.value)}
      value={password}
      fullWidth
    />
  )

  const renderMessage = () => (
    <div className={styles.messageContainer}>
      <div className={styles.errorMessage}>{errorMessage}</div>
    </div>
  )

  const renderResetForm = () => (
    <div className={styles.formContainer}>
      <h2>パスワードリセット</h2>
      {renderMessage()}
      {renderEmailInput()}
      <div className={styles.buttonContainer}>
        {button('確認メールを送信', onClickResetPass, 'primary')}
        {button('キャンセル', e => onChangeResetPassMode(false))}
      </div>
    </div>
  )

  const renderSnsButtons = () => (
    <div className={styles.buttonContainer}>
      {button('Googleで続ける', onClickGoogleLogin, 'default', <GoogleIcon />)}
      {button(
        'Facebookで続ける',
        onClickFacebookLogin,
        'default',
        <FacebookIcon />
      )}
      {button(
        'Twitterで続ける',
        onClickTwitterLogin,
        'default',
        <TwitterIcon />
      )}
    </div>
  )

  const renderSignupForm = () => (
    <div className={styles.formContainer}>
      <h2>新規登録</h2>
      <p>
        アカウントをお持ちの方は
        <span
          className={styles.link}
          onClick={e => {
            onChangeSignupMode(false)
            sendGaEvent('go_to_login', 'login', 'アカウントをお持ちの方')
          }}
        >
          ログイン
        </span>
      </p>
      {renderMessage()}
      {renderSnsButtons()}
      <h2>または</h2>
      <p>メールアドレスで登録</p>
      {renderEmailInput()}
      {renderPasswordInput()}
      <p>
        登録には、<a href='/tos'>利用規約</a>と
        <a href='policy'>プライバシーポリシー</a>
        <br />
        への同意が必要です
      </p>
      <div className={styles.buttonContainer}>
        {button('同意して登録', onClickSignUp, 'primary')}
      </div>
    </div>
  )

  const renderLoginForm = () => (
    <div className={styles.formContainer}>
      <h2>ログイン</h2>
      <p>
        はじめてご利用の方は
        <span
          className={styles.link}
          onClick={e => {
            onChangeSignupMode(true)
            sendGaEvent('go_to_signup', 'login', 'はじめてご利用の方')
          }}
        >
          新規登録
        </span>
      </p>
      {renderMessage()}
      {renderSnsButtons()}
      <h2>または</h2>
      <p>メールアドレスでログイン</p>
      {renderEmailInput()}
      {renderPasswordInput()}
      <div
        className={styles.resetPass}
        onClick={e => onChangeResetPassMode(true)}
      >
        パスワードを忘れた場合
      </div>
      <div className={styles.buttonContainer}>
        {button('ログイン', onClickLogin, 'primary')}
      </div>
    </div>
  )

  const renderProgress = () => (
    <div className={styles.formContainer}>
      <div style={{ textAlign: 'center', margin: '48px 0' }}>
        <CircularProgress />
      </div>
      <h3>ログインしています…</h3>
    </div>
  )

  return (
    <div className={styles.container}>
      {isLoggingIn
        ? renderProgress()
        : resetPassMode
        ? renderResetForm()
        : signupMode
        ? renderSignupForm()
        : renderLoginForm()}
    </div>
  )
}

Login.propTypes = {
  signup: PropTypes.bool
}
