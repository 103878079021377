import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './ChatBaloon.module.scss'

export const IMG_DOCTOR = 'image/doctor'
export const IMG_STUDENT = 'image/student'

export const POS_LEFT = 'position/left'
export const POS_RIGHT = 'position/right'

export function ChatBaloon({
  children,
  image = IMG_DOCTOR,
  position = POS_LEFT
}) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.imgStudent]: image === IMG_STUDENT,
        [styles.imgDoctor]: image === IMG_DOCTOR,
        [styles.posLeft]: position === POS_LEFT,
        [styles.posRight]: position === POS_RIGHT
      })}
    >
      <div className={styles.text}>{children}</div>
    </div>
  )
}
export default ChatBaloon

ChatBaloon.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
  position: PropTypes.string
}

export function ChatBaloonDoctor({ children }) {
  return (
    <ChatBaloon image={IMG_DOCTOR} position={POS_LEFT}>
      {children}
    </ChatBaloon>
  )
}

export function ChatBaloonStudenr({ children }) {
  return (
    <ChatBaloon image={IMG_STUDENT} position={POS_RIGHT}>
      {children}
    </ChatBaloon>
  )
}
