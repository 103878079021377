import PropTypes from 'prop-types'
import React from 'react'

import RabbitTypeImage from './RabbitTypeImage'
import RabbitTypeText from './RabbitTypeText'

import styles from './RabbitType.module.scss'

export const RabbitType = ({ rabbitType, precision }) => {
  return (
    <div className={styles.container}>
      あなたのうさぎは
      <RabbitTypeImage typeId={rabbitType.typeId} precision={precision} />
      <RabbitTypeText rabbitType={rabbitType} precision={precision} />
    </div>
  )
}

RabbitType.propTypes = {
  rabbitType: PropTypes.object,
  precision: PropTypes.number
}

export default RabbitType
