import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import PrecisionScale from '../ui/PrecisionScale'

import styles from './PrecisionBar.module.scss'

export const PrecisionBar = ({ precision = 0, onClickFinish = () => {} }) => {
  const canJump = precision > 0.75
  return (
    <div
      className={ClassNames(styles.container, { [styles.canJump]: canJump })}
      onClick={() => (canJump ? onClickFinish() : () => {})}
    >
      <span className={styles.precision}>
        精度：
        <PrecisionScale precision={precision} />
      </span>
      {canJump ? (
        <span className={styles.jump}>　この回答までで結果を見る ▶</span>
      ) : (
        <></>
      )}
    </div>
  )
}
PrecisionBar.propTypes = {
  precision: PropTypes.number,
  onClickFinish: PropTypes.func
}
