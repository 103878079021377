import {
  analyze as analyzeV1,
  analyzePrecision as analyzePrecisionV1
} from './v1/analyze'
import { getHistogram as getHistogramV1 } from './v1/histogramProvider'
import { getWeightedItem as getWeightedItemV1 } from './v1/itemProvider'

import itemDataV1 from './v1/itemData.json'
import typeDataV1 from './v1/typeData.json'

export function analyze(input) {
  const { logicVersion } = input
  switch (logicVersion) {
    case 1:
      return { ...analyzeV1(input), logicVersion }
    default:
      console.warn('[analyze]: logicVersion is not defined.')
      return {}
  }
}

export function analyzePrecision(input) {
  const { logicVersion } = input
  switch (logicVersion) {
    case 1:
      return { ...analyzePrecisionV1(input), logicVersion }
    default:
      console.warn('[analyzePrecision]: logicVersion is not defined.')
      return {}
  }
}

export const getWeightedItem = input => {
  const { logicVersion } = input
  switch (logicVersion) {
    case 1:
      return getWeightedItemV1(input)
    default:
      console.warn('[getWeightedItem]: logicVersion is not defined.')
      return {}
  }
}

export const getHistogram = input => {
  const { logicVersion } = input
  switch (logicVersion) {
    case 1:
      return getHistogramV1(input)
    default:
      console.warn('[getHistogram]: logicVersion is not defined.')
      return {}
  }
}

export const getItemData = input => {
  const { logicVersion } = input
  switch (logicVersion) {
    case 1:
      return itemDataV1
    default:
      console.warn('[getItemData]: logicVersion is not defined.')
      return {}
  }
}

export const getTypeData = input => {
  const { logicVersion } = input
  switch (logicVersion) {
    case 1:
      return typeDataV1
    default:
      console.warn('[getTypeData]: logicVersion is not defined.')
      return {}
  }
}
