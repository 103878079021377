// Takram Confidential
// Copyright (C) 2019-Present Takram

import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { sendGaEvent } from '../../GaEvent'
import { toggleFavoriteArticle } from '../../stores/user'
import { LikeIconOff, LikeIconOn } from '../ui/ButtonIcons'
import DoctorDialog from '../ui/DoctorDialog'

import styles from './ListCatalog.module.scss'

export function ListCatalog({ articles = [] }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { favoriteArticleIds } = useSelector(state => state.user.data)
  const { auth } = useSelector(state => state.user)
  const dialogRef = useRef()

  const onClickLogin = () => {
    sendGaEvent('go_to_signup', 'login', '記事のお気に入り追加(Icon)')
    history.push(`/signup`)
  }

  const onClickContent = id => {
    history.push(`/article/${id}`)
  }

  const renderFavoButton = content => {
    const articleId = content.copingContentsId
    const isMyFavorite = favoriteArticleIds.includes(articleId)
    const onClickFavo = e => {
      e.stopPropagation()
      const action = isMyFavorite ? 'remove_favorite' : 'add_favorite'
      sendGaEvent(action, 'favorite', auth.uid == null ? 'no_login' : articleId)
      if (auth.uid == null) {
        dialogRef.current.handleOpen(e)
      } else {
        dispatch(toggleFavoriteArticle(articleId))
      }
    }
    return (
      <div className={styles.favoButton} onClick={onClickFavo}>
        {isMyFavorite ? <LikeIconOn /> : <LikeIconOff />}
      </div>
    )
  }

  const renderContent = content => {
    return (
      <div
        className={styles.content}
        onClick={e => onClickContent(content.copingContentsId)}
        key={content.copingContentsId}
      >
        <div className={styles.text}>
          <div className={styles.title}>{content.title}</div>
          <div className={styles.tags}>
            <div className={styles.tag}>#{content.categoryLarge}</div>
            {content.categoryLarge !== content.categoryMiddle &&
            content.categoryMiddle !== null ? (
              <div className={styles.tag}>#{content.categoryMiddle}</div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.thumb}>
          <img
            alt='thumb'
            src={`${process.env.PUBLIC_URL}/images/article/${content.thumbImage}`}
          />
        </div>
        {renderFavoButton(content)}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <DoctorDialog
        title='ログインが必要です'
        text='記事をお気に入りに追加するには、ログインが必要じゃ'
        ref={dialogRef}
        buttons={[
          { name: 'キャンセル', value: () => {} },
          { name: 'ログイン', value: onClickLogin }
        ]}
      />
      {articles.map(content => renderContent(content))}
    </div>
  )
}

ListCatalog.propTypes = {
  articles: PropTypes.array
}

export default ListCatalog
