import * as d3 from 'd3'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import styles from './Histgram.module.scss'

export function Histgram({ value = 50, histgramData = [] }) {
  const graphRef = useRef()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [svg, setSvg] = useState(null)
  const [ndistData, setNdistData] = useState([])
  const getNormalDist = x =>
    (1.0 / Math.sqrt(2 * Math.PI * 300)) *
    Math.exp((Math.pow(x - 50, 2) / (2 * 300)) * -1)

  useEffect(() => {
    const node = graphRef.current
    const w = node.getBoundingClientRect().width
    const h = node.getBoundingClientRect().height
    const svg = d3.select(node).attr('height', h).attr('width', w)
    if (histgramData.length === 0) {
      const data = []
      for (let x = 5; x < 100; x += 5) {
        data.push({ x, y: getNormalDist(x) })
      }
      setNdistData(data)
    } else {
      setNdistData(histgramData)
    }
    svg.append('g').attr('class', 'border-y')
    svg.append('g').attr('class', 'graph-rects')
    svg.append('g').attr('class', 'graph-icon')
    svg.append('g').attr('class', 'level-bar')
    svg.append('g').attr('class', 'level-bar-label')
    setWidth(w)
    setHeight(h)
    setSvg(svg)
  }, [histgramData])

  const drawHistgram = useCallback(() => {
    const xStart = () => 0
    const yStart = () => height - 60
    const xEnd = () => width
    const yEnd = () => 8
    const yMax = Math.max(...ndistData.map(d => d.y)) * 1.2
    const xScale = d3
      .scaleBand()
      .rangeRound([xStart(), xEnd()])
      .padding(0.4)
      .domain(ndistData.map(d => d.x))
    const yScale = d3.scaleLinear().domain([0, yMax]).range([yStart(), yEnd()])
    const lines = svg
      .select('.border-y')
      .selectAll('line')
      .data([0, 0.25, 0.5, 0.75, 1.0])
    lines.exit().remove()
    lines
      .enter()
      .append('line')
      .attr('x1', xStart())
      .attr('x2', d => (d === 1.0 ? xEnd() - 30 : xEnd()))
      .attr('y1', d => yScale(yMax * d))
      .attr('y2', d => yScale(yMax * d))
      .attr('stroke-width', 1)
      .attr('stroke', '#ececec')
    const rects = svg.select('.graph-rects').selectAll('rect').data(ndistData)
    const t = ndistData.find(el => el.x <= value && value < el.x + 5)
    const target = t == null ? { x: 0, y: 0 } : t
    if (xScale(target.x) == null) return
    rects.exit().remove()
    rects
      .enter()
      .append('rect')
      .attr('x', d => xScale(d.x) + xScale.bandwidth() / 2)
      .attr('y', d => yScale(d.y))
      .attr('width', xScale.bandwidth())
      .attr('height', d => yStart() - yScale(d.y))
      .attr('fill', d => (d.x === target.x ? '#EB5959' : '#E9E9E9'))
    rects
      .attr('x', d => xScale(d.x) + xScale.bandwidth() / 2)
      .attr('y', d => yScale(d.y))
      .attr('height', d => yStart() - yScale(d.y))
      .attr('fill', d => (d.x === target.x ? '#EB5959' : '#E9E9E9'))
    svg.select('.graph-icon').selectAll('*').remove()
    svg
      .select('.graph-icon')
      .append('image')
      .attr('xlink:href', `${process.env.PUBLIC_URL}/images/human.svg`)
      .attr('width', 9)
      .attr('height', 22)
      .attr('x', xScale(target.x) + 4)
      .attr('y', yScale(target.y) - 24)
  }, [svg, value, ndistData, width, height])

  const drawLevelBar = useCallback(() => {
    const bar = svg.select('.level-bar').selectAll('rect').data([0, value])
    bar.exit().remove()
    bar
      .enter()
      .append('rect')
      .attr('x', d => 0)
      .attr('y', d => height - 45)
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('width', d => (d === 0 ? width : (d / 100) * width))
      .attr('height', 16)
      .attr('fill', d => (d === 0 ? '#FDDBDB' : '#EB5959'))
    bar
      .attr('width', d => (d === 0 ? width : (d / 100) * width))
      .attr('fill', d => (d === 0 ? '#FDDBDB' : '#EB5959'))
    const label = svg
      .select('.level-bar-label')
      .style('font-family', 'sans-serif')
      .style('font-size', '12px')
    label
      .append('text')
      .attr('text-anchor', 'start')
      .attr('x', 0)
      .attr('y', height - 4)
      .text('低い')
    label
      .append('text')
      .attr('text-anchor', 'end')
      .attr('x', width)
      .attr('y', height - 4)
      .text('高い')
    label
      .append('text')
      .attr('text-anchor', 'end')
      .attr('x', width)
      .attr('y', 12)
      .text('人数')
  }, [svg, value, width, height])

  useEffect(() => {
    if (svg == null) return
    drawHistgram()
    drawLevelBar()
  }, [svg, drawHistgram, drawLevelBar])

  return (
    <div className={styles.container}>
      <svg ref={graphRef} className={styles.graph} />
    </div>
  )
}

Histgram.propTypes = {
  value: PropTypes.number,
  histgramData: PropTypes.array
}
