import { useEffect } from 'react'

export const useConversion = () => {
  useEffect(() => {
    if (!window._lt) return
    window._lt(
      'send',
      'cv',
      {
        type: 'Conversion'
      },
      [process.env.REACT_APP_LINE_TRACKING_TAG_ID]
    )
  }, [])
}
